<template>
  <div>
    <div class="pageSize" v-if="!showData">
      <Head text="健康管理方案" bgColor="#5aaca9"></Head>
      <div class="analysisReport_box5">
        <div class="analysisReport_box5_info2">
          <div class="analysisReport_box5_info2_title a_flex" style="display: inline-flex;">
            {{ titleA }}
          </div>
          <div class="analysisReport_box5_info2_titleA">
            {{ titleB }}
          </div>
        </div>
        <div class="analysisReport_box5_content">
          <div class="analysisReport_box5_content_info">
            <div class="analysisReport_box5_content_info_title">
              一、目前您的主要健康隐患：
            </div>
            <div class="analysisReport_box5_content_list" style="padding-bottom: 20px;">
              <div class="analysisReport_box5_content_list_title">
                <div class="dot" :style="{ backgroundColor: objData.healthTrouble.projectColorCode }"></div>
                <div class="text_content" :style="{ color: objData.healthTrouble.projectColorCode }"> {{
                  objData.healthTrouble.projectName }}</div>
              </div>
              <div class="source">
                <div class="source_text">来源依据</div>
                <div class="source_line"> </div>
              </div>
              <div class="ques_indicators" v-if="objData.healthTrouble?.originalInfo.length > 0">
                <template v-for="(item, index) in objData.healthTrouble.originalInfo">
                  <div class="ques" v-if="item.title != '临床指标'">
                    <div class="ques_indicators_title">问卷</div>
                    <div class="ques_indicators_text" v-for="(item1, index1) in item.text" :key="index">
                      {{ item1.text }}
                    </div>
                  </div>
                  <div class="indicators" v-else>
                    <div class="ques_indicators_title">临床指标</div>
                    <div class="indicators_table">
                      <div class="th" style="width: 130px;">指标</div>
                      <div class="th">等级</div>
                      <div class="th">结果</div>
                      <div class="th" style="width: 110px;">参考</div>
                    </div>
                    <div class="indicators_table">
                      <div class="th" style="width: 130px;background-color: #EFEFEF;margin-top: 0;color: #474F57;">{{
                        item.text[0].text }}
                      </div>
                      <div class="th" style="background-color: #EFEFEF;margin-top: 0;color: #474F57;"
                        :style="{ color: item.text[0].colorCode }">
                        {{ item.text[0].level }}</div>
                      <div class="th" style="background-color: #EFEFEF;margin-top: 0;color: #474F57;">{{
                        item.text[0].value }}</div>
                      <div class="th" style="background-color: #EFEFEF;width: 110px;margin-top: 0;color: #474F57;">{{
                        item.text[0].refrence }}</div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="analysisReport_box5_content_info" style="margin-top: 25px;">
            <div class="analysisReport_box5_content_info_title">
              二、影响您以上健康隐患的相关失衡因素分析：
            </div>
            <div class="analysisReport_box5_content_list" v-for="(item, index) in objData.factorTroubleList"
              :key="index">
              <div class="analysisReport_box5_content_list_title">
                <div class="dot" :style="{ backgroundColor: item.projectColorCode }"></div>
                <div class="text_content" :style="{ color: item.projectColorCode }"> {{
                  item.projectName }}</div>
              </div>
              <div class="healthTrouble_text">{{ item.description }}</div>
            </div>
          </div>
          <div class="analysisReport_box5_content_info" style="margin-top: 25px;">
            <div class="analysisReport_box5_content_info_title">
              三、影响您健康隐患的生活方式因素分析:
            </div>
            <template v-if="objData.liveTroubleList.length > 0">
              <div class="analysisReport_box5_content_list" v-for="(item, index) in objData.liveTroubleList"
                :key="index">
                <div class="analysisReport_box5_content_list_title">
                  <div class="dot" :style="{ backgroundColor: item.projectColorCode }"></div>
                  <div class="text_content" :style="{ color: item.projectColorCode }"> {{
                    item.projectName }}</div>
                </div>
                <div class="healthTrouble_text">{{ item.description }}</div>
              </div>
            </template>
            <template v-else>
              <div style="font-size: 20px;padding-top: 10px;padding-left: 10px;">
                暂无数据
              </div>
            </template>
          </div>
        </div>
        <div class="analysisReportPage" style="position: absolute;right: 60px;bottom: 60px;font-size: 20px;color: #000">
          {{ pageNum }} of {{ pageAllNum }}
        </div>
      </div>
    </div>
    <template v-else>
      <div class="pageSize">
        <Head text="健康管理方案" bgColor="#5aaca9"></Head>
        <div class="analysisReport_box5">
          <div class="analysisReport_box5_info2">
            <div class="analysisReport_box5_info2_title a_flex" style="display: inline-flex;">
              {{ titleA }}
            </div>
            <div class="analysisReport_box5_info2_titleA">
              {{ titleB }}
            </div>
          </div>
          <div class="analysisReport_box5_content">
            <div class="analysisReport_box5_content_info">
              <div class="analysisReport_box5_content_info_title">
                一、目前您的主要健康隐患：
              </div>
              <div class="analysisReport_box5_content_list" style="padding-bottom: 20px;">
                <div class="analysisReport_box5_content_list_title">
                  <div class="dot" :style="{ backgroundColor: objData.healthTrouble.projectColorCode }"></div>
                  <div class="text_content" :style="{ color: objData.healthTrouble.projectColorCode }"> {{
                    objData.healthTrouble.projectName }}</div>
                </div>
                <div class="source">
                  <div class="source_text">来源依据</div>
                  <div class="source_line"> </div>
                </div>
                <div class="ques_indicators" v-if="objData.healthTrouble?.originalInfo.length > 0">
                  <template v-for="(item, index) in objData.healthTrouble.originalInfo">
                    <div class="ques" v-if="item.title != '临床指标'">
                      <div class="ques_indicators_title">问卷</div>
                      <div class="ques_indicators_text" v-for="(item1, index1) in item.text" :key="index">
                        {{ item1.text }}
                      </div>
                    </div>
                    <div class="indicators" v-else>
                      <div class="ques_indicators_title">临床指标</div>
                      <div class="indicators_table">
                        <div class="th" style="width: 130px;">指标</div>
                        <div class="th">等级</div>
                        <div class="th">结果</div>
                        <div class="th" style="width: 110px;">参考</div>
                      </div>
                      <div class="indicators_table">
                      <div class="th" style="width: 130px;background-color: #EFEFEF;margin-top: 0;color: #474F57;">{{
                        item.text[0].text }}
                      </div>
                      <div class="th" style="background-color: #EFEFEF;margin-top: 0;color: #474F57;"
                        :style="{ color: item.text[0].colorCode }">
                        {{ item.text[0].level }}</div>
                      <div class="th" style="background-color: #EFEFEF;margin-top: 0;color: #474F57;">{{
                        item.text[0].value }}</div>
                      <div class="th" style="background-color: #EFEFEF;width: 110px;margin-top: 0;color: #474F57;">{{
                        item.text[0].refrence }}</div>
                    </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="analysisReport_box5_content_info" style="margin-top: 25px;">
              <div class="analysisReport_box5_content_info_title">
                二、影响您以上健康隐患的相关失衡因素分析：
              </div>
              <template v-for="(item, index) in objData.factorTroubleList">
                <div class="analysisReport_box5_content_list" v-if="index < 6">
                  <div class="analysisReport_box5_content_list_title">
                    <div class="dot" :style="{ backgroundColor: item.projectColorCode }"></div>
                    <div class="text_content" :style="{ color: item.projectColorCode }"> {{
                      item.projectName }}</div>
                  </div>
                  <div class="healthTrouble_text">{{ item.description }}</div>
                </div>
              </template>
            </div>
          </div>
          <div class="analysisReportPage" style="position: absolute;right: 60px;bottom: 60px;font-size: 20px;color: #000">
            {{ pageNum }} of {{ pageAllNum }}
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="pageSize">
        <Head text="健康管理方案" bgColor="#5aaca9"></Head>
        <div class="analysisReport_box5">
          <!-- <div class="analysisReport_box5_info2">
            <div class="analysisReport_box5_info2_title a_flex" style="display: inline-flex;">
              {{ titleA }}
            </div>
            <div class="analysisReport_box5_info2_titleA">
              {{ titleB }}
            </div>
          </div> -->
          <div class="analysisReport_box5_content">
            <div class="analysisReport_box5_content_info" style="margin-top: 25px;">
              <!-- <div class="analysisReport_box5_content_info_title">
                二、影响您以上健康隐患的相关失衡因素分析：
              </div> -->
              <template v-for="(item, index) in objData.factorTroubleList">
                <div class="analysisReport_box5_content_list" v-if="index >= 6">
                  <div class="analysisReport_box5_content_list_title">
                    <div class="dot" :style="{ backgroundColor: item.projectColorCode }"></div>
                    <div class="text_content" :style="{ color: item.projectColorCode }"> {{
                      item.projectName }}</div>
                  </div>
                  <div class="healthTrouble_text">{{ item.description }}</div>
                </div>
              </template>
            </div>
            <div class="analysisReport_box5_content_info" style="margin-top: 25px;">
              <div class="analysisReport_box5_content_info_title">
                三、影响您健康隐患的生活方式因素分析:
              </div>
              <template v-if="objData.liveTroubleList.length > 0">
                <div class="analysisReport_box5_content_list" v-for="(item, index) in objData.liveTroubleList"
                  :key="index">
                  <div class="analysisReport_box5_content_list_title">
                    <div class="dot" :style="{ backgroundColor: item.projectColorCode }"></div>
                    <div class="text_content" :style="{ color: item.projectColorCode }"> {{
                      item.projectName }}</div>
                  </div>
                  <div class="healthTrouble_text">{{ item.description }}</div>
                </div>
              </template>
              <template v-else>
                <div style="font-size: 20px;padding-top: 10px;padding-left: 10px;">
                  暂无数据
                </div>
              </template>
            </div>
          </div>
          <div class="analysisReportPage" style="position: absolute;right: 60px;bottom: 60px;font-size: 20px;color: #000">
            {{ pageNum +1}} of {{ pageAllNum }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import Head from './head.vue'
import Bottom from './bottom.vue'
export default {
  data () {
    return {
      showData: false
    }
  },
  props: ['objData', 'titleA', 'titleB', 'pageNum', 'pageAllNum'],
  components: {
    Head,
    Bottom
  },
  watch: {
    objData: {
      immediate: true,
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        const totalNum = newValueA.factorTroubleList.length
        const totalNum2 = newValueA.factorTroubleList.length + newValueA.liveTroubleList.length
        console.log('totalNum', totalNum)
        if (totalNum > 7 || totalNum2 > 7) {
          this.showData = true
        } else {
          this.showData = false
        }
        // console.log('totalNum', totalNum, 'totalNum2', totalNum2);
      }
    }
  },
  methods: {
    hexToRgb (hex, opt) {
      // Remove "#" symbol from the beginning of the string
      hex = hex.replace('#', '')
      // Convert each pair of characters to decimal values for red, green, and blue
      var red = parseInt(hex[0] + hex[1], 16)
      var green = parseInt(hex[2] + hex[3], 16)
      var blue = parseInt(hex[4] + hex[5], 16)
      return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')'
    }
  }
}
</script>

<style lang="scss" scoped>
.analysisReport_box5 {
  width: 974px;
  margin: 0 auto;

  .analysisReport_box5_info2 {
    margin-top: 75px;

    .analysisReport_box5_info2_title {
      padding: 13px 50px 12px 51px;
      background: #2baea9;
      border-radius: 14px;
      font-weight: 600;
      font-size: 28px;
      color: #FFFFFF;
    }

    .analysisReport_box5_info2_titleA {
      margin-top: 14px;
      font-weight: 400;
      font-size: 20px;
      color: #474F57;
    }
  }

  .analysisReport_box5_content {
    margin-top: 34px;

    .analysisReport_box5_content_info {
      .analysisReport_box5_content_info_title {
        font-weight: 500;
        font-size: 26px;
        color: #475059;
      }

      .analysisReport_box5_content_list {
        width: 950px;
        min-height: 40px;
        margin: 0 auto;
        border: 1px solid #efefef;
        margin-top: 20px;
        border-radius: 6px;

        .analysisReport_box5_content_list_title {
          display: flex;
          align-items: center;
          width: 100%;
          height: 40px;
          background-color: #efefef;
          padding-left: 20px;

          .dot {
            width: 7px;
            height: 7px;
            border-radius: 3.5px;
            background-color: red;
          }

          .text_content {
            padding-left: 10px;
            font-size: 20px;
          }
        }

        .healthTrouble_text {
          padding: 10px 0 10px 20px;
          font-size: 18px;
        }

        .source {
          display: flex;
          align-items: center;
          margin-left: 20px;
          margin-top: 20px;

          .source_text {
            font-size: 18px;
          }

          .source_line {
            width: 840px;
            height: 3px;
            margin-left: 10px;
            background-color: #efefef;
          }
        }

        .ques_indicators {
          display: flex;
          justify-content: space-between;
          padding-left: 20px;
          padding-top: 16px;

          .ques_indicators_title {
            width: 200px;
            height: 30px;
            line-height: 30px;
            background-color: #efefef;
            font-size: 18px;
            border-radius: 6px;
            padding-left: 10px;
          }

          .ques_indicators_text {
            font-size: 16px;
            padding-top: 10px;
          }

          .ques {
            flex: 1;
          }

          .indicators {
            flex: 1;

            .indicators_table,
            .indicators_th {
              display: flex;
              font-size: 14px;

              .th {
                margin-top: 10px;
                width: 100px;
                padding: 5px;
                color: #FFFFFF;
                background-color: #2baea9;
                margin-left: 2px;
                text-align: center;
              }
            }
          }
        }
      }

      .analysisReport_box5_content_info_ul1 {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-top: 37px;

        .analysisReport_box5_content_info_ul1_img {
          width: 35px;
          height: 31px;
        }

        .analysisReport_box5_content_info_ul1_text {
          margin-left: 16px;
          font-weight: 400;
          font-size: 24px;
          color: #474F57;
        }

        .analysisReport_box5_content_info_ul1_li {
          display: flex;
          align-items: center;
          margin-right: 68px;
          margin-bottom: 31px;

          .analysisReport_box5_content_info_ul1_li_text {
            display: flex;
            align-items: center;
            width: 220px;
            height: 50px;
            // background: #FAE8EA;
            border-radius: 15px;

            .analysisReport_box5_content_info_ul1_li_text_d {
              margin: 0 13px 0 23px;
              width: 7px;
              height: 7px;
              background: #D51931;
              border-radius: 50%;
            }

            .analysisReport_box5_content_info_ul1_li_textT {
              font-weight: 400;
              font-size: 20px;
              color: #0a1721;
            }
          }

          .analysisReport_box5_content_info_ul1_li_level {
            margin-left: 27px;
            width: 43px;
            height: 41px;
            background: #FAE8EA;
            border-radius: 7px;
            border: 1px solid #D51931;
            font-size: 23px;
            color: #D51931;
          }
        }

        .analysisReport_box5_content_info_ul1_li:nth-last-of-type(1) {
          margin-bottom: 0;
        }

        .analysisReport_box5_content_info_ul1_li:nth-last-of-type(2) {
          margin-bottom: 0;
        }
      }
    }
  }
}

.analysisReport_box5_content_info_ul1_li_width {
  display: flex;
  align-items: center;
  width: 389px;
}
</style>
