<template>
    <div class="pageSize main">
        <Head text="健康管理方案" bgColor="#C8EBED"></Head>
        <div style="padding-left: 104px;">
            <div class="title">
                <div class="title_left">04</div>
                <div class="title_right">
                    常见干预药物
                    <div class="line"></div>
                </div>
            </div>
            <div class="text_itle">通常用于降低高甘油三醋药物如下：</div>
            <div class="intervene">
                <div class="intervene_title">
                    <span class="round"></span>
                    贝特类调脂药:
                </div>
                <div class="intervene_text"> 非诺贝特、苯扎贝特和吉非罗齐等，通过降低肝脏极低密度脂蛋白(VLDL)胆固醇 的产量和加速从血液中去除甘油三酷来降低甘油三。</div>
            </div>
            <div class="references"> 参考文献</div>
            <div class="references_text">1. Drenjancevic I et al. Omega-3 Polyunsaturated Fatty Acids-Va1. Drenjancevic
                I et
                al. Omega-3 Polyunsaturated Fatty Acids-Va1. Drenjancevic I et al. Omega-3 Polyunsaturated Fatty
                Acids-Va
            </div>
        </div>
    </div>
</template>

<script>
import Head from './head.vue'
export default {
    components: {
        Head
    }
}
</script>

<style lang="scss" scoped>
.main {
    // padding-left: 104px;
    background-image: url('https://image.giantgocloud.com/www/ImageMapping/image/20240430/44ED3C6D40284A83963DA7E04C1E3889.png');
    background-size: cover;

    .title {
        display: flex;

        .title_left {
            font-family: Bahnschrift;
            font-weight: 400;
            font-size: 114px;
            color: #DFE3E8;
            padding-top: 100px;
        }

        .title_right {
            position: relative;
            font-family: Source Han Sans CN;
            font-weight: 500;
            font-size: 42px;
            color: #2baea9;
            padding-top: 120px;

            .line {
                width: 80px;
                height: 2px;
                background-color: #2baea9;
                margin-top: 20px;
            }
        }
    }

    .text_itle {
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 36px;
        color: #2baea9;
    }

    .intervene {
        padding-top: 30px;

        .intervene_title {
            font-family: Source Han Sans SC;
            font-weight: 400;
            font-size: 36px;
            color: #474F57;

            .round {
                display: inline-block;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid #2baea9;
            }
        }

        .intervene_text {
            padding-top: 10px;
            padding-right: 358px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            font-size: 20px;
            color: #474F57;
        }
    }

    .references {
        color: #2baea9;
        font-size: 42px;
        padding-top: 80px;
    }

    .references_text {
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 18px;
        color: #474F57;
        padding-top: 30px;
        padding-right: 123px;
    }
}
</style>
