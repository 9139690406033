<template>
  <div>
    <div v-if="obj1.length" v-for="(item, index) in obj1" :key="index" class="funMedicineBox6 pageSize">
      <div>
        <Head text="健康管理方案" bgColor="#505EA5" bgColorRight="#DDE3F1"></Head>
        <div class="pages17_content">
          <div class="funMedicinesecod_line"></div>
          <div class="funMedicinesecod_line_a">
            <div class="pages17_content_top_title">
              <div class="pages17_content_top_title_t">{{ item.FunctionName }}</div>
              <img style="width: 100%;"
                src="https://image.giantgocloud.com/www/ImageMapping/image/20240113/801B472E85764831BFBBDFFDC59E036C.png"
                alt="" />
            </div>
            <!-- <div class="pages17_content_top_title_q"  :style="{ color: item.FunctionColor,borderColor:item.FunctionColor,backgroundColor:hexToRgb(item.FunctionColor,0.1)}">{{ item.FunctionLevel }}</div> -->
          </div>

          <div class="pages17_content_top_content">{{ item.FuncIntroduce }}</div>

         <div class="funMedicine6" v-for="(item1, index1) in item.contentA" :key="index1"
            :id="'getDivHeight' +String(idIndex) +String(index) + String(index1) ">
            <div class="funMedicine6_box">
              <div>
                <div class="funMedicine6HeadTop">
                  <div>
                    <div class="funMedicine6Head">
                      <span>您的{{ item1.indicatorsName }}指标为：</span>
                        <span style="color: #1386b0;">
                   {{item1.checkResult}}
                  ({{item1.indicatorsUnit}})&nbsp;&nbsp;
                    <span v-if="item1.maxRefrence>=9999">[ >={{ item1.minRefrence }} ]</span>
                    <span v-else-if="(item1.maxRefrence||item1.minRefrence===0)&&item1.maxRefrence!==0">[{{ item1.minRefrence }}-{{item1.maxRefrence}}]</span>
               </span>
                    </div>
                    <div class="funMedicine6Head_h">

                    </div>
                   <div class="process" style="margin-top: 51px;" id="processdata">
                      <div class="process1" v-for="(color, index3) in item1.colorList" :key="index3"
                        :class="index3 == item1.colorList.length - 1 ? 'process1_A' : ''" :style="{
                          width: `${(432 / item1.colorList.length).toFixed(1) + 'px'}`,
                          background: `${color.colorCode}`,
                        }">
                        <span>{{ color.colorName }}</span>
                      </div>

                     <div class="Yuan" :style="{
                        border: `4px solid ${item1.colorCode}`, top: `-11px`, left: `${((item1.calcResult / 100) * 432).toFixed(1) + 'px'}`
                      }">
                      </div>
                    </div>
                  </div>
                 <div>
                    <div class="funMedicine6Text">
                      {{ item1.indicatorsName }}
                    </div>
                    <div class="problem" >
                      <div class="problemText">
                        {{ item1.indicatorsDescription }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import Head from './head.vue'
export default {
  props: ['PagesInitData', 'PagesInitDataA'],
  data () {
    return {
      idIndex: 1,
      obj1: [],
      newlistDataA: {},
      title2: '健康分析',
      dataList: [],
      list3: [],
      list4: [],
      list5: [],
      list6: [],
      data: {},
      dan: {},
      num: 0,
      text: [],
      newList: [],
      iLength: 0,
      pageNum: 0,
      pageNum1: 0,
      pageNum2: 0,
      pagesQ: 0,
      pagesI: 0
    }
  },
  components: {
    Head
  },
  watch: {
    PagesInitData: {
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        // console.log(newValueA, 'newValueA11111111111111')
      }
    }
  },
  mounted () {
    // console.log('aaaaaaaaaaaaaa')
    const that = this
    const id = this.$query('id')
    this.obj1 = []
    for (let i = 0; i < this.PagesInitData.length; i++) {
      this.PagesInitData[i].dataListA = []
    }
    this.obj1 = []
    // console.log('数据17', this.obj1)
    this.init()
  },
  methods: {

    init () {
      const that = this
      // console.log('that.pagesI', that.pagesI)
      if (that.pageNum < this.PagesInitData.length) {
        const a = {
          FuncIntroduce: this.PagesInitData[that.pageNum].typeDescription,
          FunctionColor: this.PagesInitData[that.pageNum].typeColorCode,
          FunctionLevel: this.PagesInitData[that.pageNum].typeLevel,
          FunctionName: this.PagesInitData[that.pageNum].typeName,
          contentA: []
        }
        this.obj1.push(a)
        // console.log('this.obj1', this.obj1)
        //   console.log('this.obj1000', this.obj1)
        this.init1()
      }
    },
    init1 () {
      const that = this
      if (that.pagesQ < this.PagesInitData[that.pageNum].indicatorsList.length) {
        this.obj1[that.pagesI].contentA.push(this.PagesInitData[that
          .pageNum].indicatorsList[that.pagesQ])
        that.pagesQ++
        setTimeout(() => {
          that.$forceUpdate()
          //   console.log('0000', 'getDivHeight' + String(that.idIndex) + String(that.pageNum) + String(that
          //     .pageNum1) + String(that.pageNum2))
          // console.log('String(that.pagesI) + String(that.pagesQ)', String(that.pagesI) + String(that.pageNum1))
          const getDivHeightBox = document.getElementById('getDivHeight' + String(that.idIndex) + String(that.pagesI) + String(that.pageNum1))
          // console.log('getDivHeightBox.offsetLeft', getDivHeightBox.offsetLeft)
          if (getDivHeightBox.offsetLeft > 1000) {
            this.obj1[that.pagesI].contentA.splice(-1, 1)
            that.$forceUpdate()
            // console.log('this.obj1xxx', this.obj1)
            that.pageNum1 = 0
            that.pagesI++
            that.pagesQ--
            that.init()
          } else {
            that.pageNum1++
            that.init1()
          }
        }, 1)
      } else {
        that.pagesI++
        that.pagesQ = 0
        that.pageNum++
        that.pageNum1 = 0
        that.pageNum2 = 0
        that.init()
      }
    },
    // 函数自调用
    setnewdata () {
      const that = this
    },
    hexToRgb (hex, opt) {
      // Remove "#" symbol from the beginning of the string
      hex = hex.replace('#', '')
      // Convert each pair of characters to decimal values for red, green, and blue
      var red = parseInt(hex[0] + hex[1], 16)
      var green = parseInt(hex[2] + hex[3], 16)
      var blue = parseInt(hex[4] + hex[5], 16)
      return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')'
    }
  }
}
</script>

<style lang='scss' scoped>
.funMedicinesecod_line_a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.funMedicinesecod_line {
  position: absolute;
  top: 0;
  margin: 40px 0;
}

.pages17_content_top_title_q {
  position: relative;
  left: -98px;
  width: 56px;
  top: -26px;
  height: 48px;
  background: #FAE8EA;
  border: 1px solid;
  border-radius: 14px;
  text-align: center;
  line-height: 48px;
  font-size: 40px;
}

.funMedicineBox6 {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
 width: 1190px;

  .pages17_content {
    box-sizing: border-box;
    padding-left: 98px;
    column-count: 2;
    column-fill: auto;
    justify-content: space-around;
    flex-wrap: wrap;
    height: 1480px;
    padding-top: 50px;
    column-span: all;
  }

  // padding: 80px 60px 0 60px;

  .funMedicine6 {
    position: relative;
    // height: 841px;
    // padding: 0 0 0 30px;

    .funMedicine6_box {
      display: inline-block;
      width: 439px;
      position: relative;
      margin-bottom: 80px;
      .process {
        position: relative;
        display: flex;
        // justify-content: center;
        text-align: center;
        margin-top: 30px;

        span {
          display: inline-block;
          transform: translateY(10px);
          margin-top: 13px;
          font-size: 16px;
          color: #4E4E4E;
        }

        .process1 {
          height: 7px;
          background: #5ec692;
          opacity: 1;
        }

        .process1:nth-of-type(1) {
          height: 7px;
          background: #d51931;
          opacity: 1;
          border-radius: 50px 0px 0px 50px;
        }

        .process1:nth-last-of-type(1) {
          height: 7px;
          background: #e29a30;
          opacity: 1;
          border-radius: 0px 50px 50px 0px !important;
        }

        .process1_A {
          height: 7px;
          background: #e29a30;
          opacity: 1;
          border-radius: 0px 50px 50px 0px !important;
        }
      }

      // margin: 20px 0;
    }

    /* 设置两张图片的位置 */
    .funMedicine6HeadTop {
      // -webkit-column-break-inside: avoid;
      // margin-top: 10px;
    }

    .funMedicine6Head {
      font-weight: 700;
      position: relative;
      left: -6px;
      margin-top: 5px;
     font-size: 25px;
     color: #4E4E4E;

    }

    .funMedicine6Footer {
      position: absolute;
      width: 40px;
      height: 40px;
      background: #1386b0;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      right: 0px;
      bottom: -10px;
      // background: url("../../../assets/analysis/be9299d8ce7aecbcc66619a0dd3f403.png") no-repeat;
      background-size: contain;
      text-align: center;
      font-size: 13px;
      padding-left: 2px;
      box-sizing: border-box;
      font-family: DIN-Bold, DIN;
      font-weight: bold;
      color: #ffffff;
      line-height: 33px;
    }

    .funMedicine6Cloumns {
      // margin-top: 12px;
      // margin-left: 44px;
      width: 122px;
      height: 7px;
      background: linear-gradient(117deg, #1386B0 0%, rgba(19, 134, 176, 0) 100%);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      // margin: 10px 0;
    }

    .funMedicine6Num {
      width: 57px;
      height: 20px;
      font-size: 12px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #4e4e4e;
      line-height: 24px;
      margin-top: 20px;
      -webkit-background-clip: text;
    }

    .Yuan {
      position: absolute;
      display: inline-block;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: white;
    }

    .processText {
      width: 528px;
      height: 26px;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #4e4e4e;
      line-height: 26px;
      margin-top: 33px;
      margin-left: 44px;

      span {
        color: #1386b0;
      }
    }

    .funMedicine6Cloumn {
      display: inline-block;
      width: 4px;
      height: 30px;
      margin-right: 8px;
      margin-left: 45px;
      margin-top: 51px;
      vertical-align: middle;
      background: linear-gradient(117deg,
          #1386b0 0%,
          rgba(19, 134, 176, 0) 100%);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }

    .funMedicine6Text {
      margin-top: 92px;
      display: inline-block;
      width: 428px;
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #505EA5;
      letter-spacing: 1px;
    }

    .problem {
      margin-top: 27px;
      width: 458px;
      font-size: 20px;
      font-weight: 500;
      .problemText {
        color: #4e4e4e;
        // padding: 10px 0;

        span {
          color: #1386b0;
          margin-right: 3px;
        }
      }
    }

    .problem:nth-child(1) {
      // margin-top: 24px;
    }

    .footers {
      margin-top: 12px;
      margin-left: 45px;
      color: #1386b0;
    }
  }
}

.pages17_content_top_title {
  position: relative;
  left: -100px;
  width: 474px;
  min-height: 88px;

  .pages17_content_top_title_t {
  position: absolute;
  left: 88px;
  top: -12px;
  font-size: 46px;
  color: #3A6DA5;
  }
}
.funMedicine6Head_h{
  width: 262px;
  height: 9px;
  background: linear-gradient(90deg, #505EA5 0%, #FFFFFF 100%);
  margin-top: 22px;
}
.pages17_content_top_content {
  width: 428px;
  font-weight: 400;
  font-size: 24px;
  color: #474F57;
  line-height: 40px;
  margin-bottom: 101px;

}</style>
