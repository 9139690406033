<template>
  <div class="testReport_head">
    <div class="testReport_head_content">
      <div class="testReport_head_content_info" :style="'border-bottom:1px solid ' + bgColor">
        <div class="testReport_head_content_info_icon">
          <div class="testReport_head_content_info_icon_div" :style="'background:' + bgColor"></div>
          <div class="testReport_head_content_info_icon_div" :style="'background:' + bgColor"></div>
          <div class="testReport_head_content_info_icon_div" :style="'background:' + bgColor"></div>
        </div>
        <div class="testReport_head_content_info_text">
          <div class="testReport_head_content_info_text_left">姓名</div>
          <div class="testReport_head_content_info_text_right">{{ userInfo.userName }}</div>
          <div class="testReport_head_content_info_text_S" :style="'background:' + bgColor"></div>
          <div class="testReport_head_content_info_text_left">性别</div>
          <div class="testReport_head_content_info_text_right">{{ userInfo.sex }}</div>
          <div class="testReport_head_content_info_text_S" :style="'background:' + bgColor"></div>
          <div class="testReport_head_content_info_text_left">出生日期</div>
          <div class="testReport_head_content_info_text_right">{{ userInfo.userBirthDay }}</div>
          <div class="testReport_head_content_info_text_S" :style="'background:' + bgColor"></div>
          <div class="testReport_head_content_info_text_left">年龄</div>
          <div class="testReport_head_content_info_text_right" style="margin-right: 0;">{{ userInfo.userAge }}</div>
        </div>
      </div>
      <div class="testReport_head_content_right" :style="'background-color:' + bgColorRight + ';'">
        <!--    <div class="testReport_head_content_right_text1">
          脂肪肝
        </div> -->
        <div class="testReport_head_content_right_text2">
          {{ text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bgColor: {
      type: String, // 类型
      required: false, // 是否必填
      default: '#2baea9' // 默认值
    },
    bgColorRight: {
      type: String, // 类型
      required: false, // 是否必填
      default: '#2baea9' // 默认值
    },

    text: {
      type: String, // 类型
      required: false, // 是否必填
      default: '健康分析' // 默认值
    }
  },
  mounted () {
    const res = JSON.parse(sessionStorage.getItem('userInfo'))
    this.userInfo = res
    // console.log(res,565322)
  },
  data () {
    return {
      userInfo: ''
    }
  }
}
</script>

<style lang='scss' scoped>
div {
  box-sizing: border-box;
}

.testReport_head {
  width: 1190px;
  padding: 0 120px 0 106px;

  .testReport_head_content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .testReport_head_content_info {
      display: flex;
      align-items: center;
      padding-bottom: 26px;

      .testReport_head_content_info_icon {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 16px;
        position: relative;
        top: 1px;

        .testReport_head_content_info_icon_div {
          margin-right: 20px;
          background: #4A9AAF;
          width: 18px;
          height: 2px;
        }
      }

      .testReport_head_content_info_text {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        color: #474F57;

        .testReport_head_content_info_text_left {
          margin-right: 20px;
          font-size: 600;
        }

        .testReport_head_content_info_text_right {
          margin-right: 20px;
          font-size: 200;
        }

        .testReport_head_content_info_text_S {
          margin-right: 20px;
          height: 16px;
          width: 2px;
          background: #4A9AAF;
        }
      }
    }

    .testReport_head_content_right {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #fff;
      width: 206px;
      height: 120px;
      background: #D0E7EF;
      border-radius: 0 0 30px 30px;

      .testReport_head_content_right_text1 {
        margin-bottom: 10px;
        font-size: 46px;
      }

      .testReport_head_content_right_text2 {
        font-size: 30px;
      }
    }
  }
}
</style>
