<template>
  <div class="pageSize">
    <img class="wh100" style="position: absolute;z-index: -1;" src="https://image.giantgocloud.com/www/ImageMapping/image/20240430/2E6018403F1E407595E816C5E4DCDEC6.png" mode="" />
    <Head text="健康管理方案" bgColor="#C8EBED"></Head>
    <div class="analysisReport_box10">
      <div class="analysisReport_box10_info2">
        <div class="analysisReport_box10_info2_title a_flex" style="display: inline-flex;">
          功能医学健康管理方案
        </div>
        <div class="analysisReport_box10_info2_titleA">
          通过对您上传的健康数据进行精准分析和进一步的功能医学分析,现给出功能医学健康管理方案,包括饮食建议、生活方式建议、食疗建议、个性化定制营养建议
        </div>
      </div>
      <div class="analysisReport_box10_title">
        <div class="analysisReport_box10_title_num">01</div>
        <div class="analysisReport_box10_title_text">
          饮食与生活方式建议
        </div>
      </div>
      <div class="analysisReport_box10_content">
          <img class="analysisReport_box10_content_icon" src="https://image.giantgocloud.com/www/ImageMapping/image/20240430/FF477F138DE24C52BAC2372DCA3462FF.png" mode="" />
        <div class="analysisReport_box10_content_ul">
          <div v-for="(item,index) in objData.suggest" :key="index" class="analysisReport_box10_content_li">
            <div class="analysisReport_box10_content_li_left">

            </div>
            <div class="analysisReport_box10_content_li_text">
              {{item}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <Bottom /> -->
  </div>
</template>

<script>
import Head from './head.vue'
import Bottom from './bottom.vue'
export default {
  props: ['objData'],
  components: {
    Head,
    Bottom
  }
}
</script>

<style lang="scss" scoped>
  .analysisReport_box10 {
    width: 974px;
    margin: 0 auto;

    .analysisReport_box10_info2 {
      margin-top: 75px;

      .analysisReport_box10_info2_title {
        padding: 13px 50px 12px 51px;
        background: #2baea9;
        border-radius: 14px;
        font-weight: 500;
        font-size: 36px;
        color: #FFFFFF;
      }

      .analysisReport_box10_info2_titleA {
        margin-top: 48px;
        font-weight: 400;
        font-size: 20px;
        color: #474F57;
      }
    }

    .analysisReport_box10_title {
      display: flex;
      align-items: center;
      margin-top: 60px;

      .analysisReport_box10_title_num {
        font-family: Bahnschrift;
        font-weight: 400;
        font-size: 114px;
        color: #DFE3E8;
      }

      .analysisReport_box10_title_text {
        position: relative;
        top: -15px;
        margin-left: 40px;
        font-weight: 500;
        font-size: 42px;
        color: #2baea9;

        &::after {
          content: "";
          position: absolute;
          bottom: -24px;
          left: 0px;
          width: 77px;
          height: 2px;
          background: #2baea9;
          z-index: 1;
        }
      }

    }
    .analysisReport_box10_content {
      box-sizing: border-box;
      padding: 91px 96px;
      position: relative;
      margin-top: 100px;
      width: 981px;
      height: 590px;
      background: #9BD3C8;
      border-radius: 77px;
      .analysisReport_box10_content_icon{
        width: 141px;
        height: 94px;
        position: absolute;
        left: 134px;
        top: -94px;
      }
      .analysisReport_box10_content_ul {
        .analysisReport_box10_content_li {
          display: flex;
          align-items: center;
            margin-bottom: 20px;
          .analysisReport_box10_content_li_left {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            border: 1px solid #FFFFFF;
            margin-right: 17px;
          }

          .analysisReport_box10_content_li_text {
            font-weight: 400;
            font-size: 24px;
            color: #FFFFFF;
          }
        }
      }
    }
  }
</style>
