<template>
  <div>
    <div v-for="(item,index) in objDataA" :key="index" class="pageSize">
      <Head bgColor="#C8EBED"></Head>
      <div class="analysisReport_box2">
        <div class="analysisReport_box2_info2">
          <div v-if="index==0" class="analysisReport_box2_info2_title a_flex">
            未上传临床指标的器官功能分析
          </div>
          <div class="analysisReport_box2_info2_ul">
            <div v-for="(item1,index1) in item.dataList" :key="index1" class="analysisReport_box2_info2_li" :id="'getDivTop'+String(pageNum)+String(index1)">
              <div class="analysisReport_box2_info2_li_header">
                <div :style="`background:${hexToRgb(item1.projectColorCode,0.1)};`" class="analysisReport_box2_info2_li_header_text">
                  <div :style="`background:${item1.projectColorCode};`" class="analysisReport_box2_info2_li_header_text_d">
                  </div>
                  <div :style="`color:${item1.projectColorCode};`" class="analysisReport_box2_info2_li_header_textT">
                    {{item1.projectName}}
                  </div>
                </div>
                <div v-if="item1.projectLevel" :style="`border-color:${item1.projectColorCode};color:${item1.projectColorCode};background:${hexToRgb(item1.projectColorCode,0.1)};`" class="analysisReport_box2_info2_li_header_level a_flex">
                  <div>
                    {{item1.projectLevel}}
                  </div>
                </div>
              </div>
              <div class="analysisReport_box2_info2_li_content">
                <div class="analysisReport_box2_info2_li_content_li">
            <!--     <div class="analysisReport_box2_info2_li_content_li_title">
                    尿微量白蛋白指标为: <span style="color: #D51931;">1.78mg/dL</span>
                  </div> -->
                  <div class="analysisReport_box2_info2_li_content_li_text">
                      {{item1.itemDescription}}
                  </div>
                </div>
               <!-- <div class="analysisReport_box2_info2_li_content_li">
                  <div class="analysisReport_box2_info2_li_content_li_title">
                    尿微量白蛋白指标为: <span style="color: #D51931;">1.78mg/dL</span>
                  </div>
                  <div class="analysisReport_box2_info2_li_content_li_text">
                      低胆固醇水平可能会减少细胞膜的稳定性: 胆固醇是细胞膜的重要组成部分，维持细胞膜的稳定性和完整性。
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <Bottom/>
    </div>
  </div>
</template>

<script>
import Head from './head.vue'
import Bottom from './bottom.vue'
export default {
  data () {
    return {
      objDataA: [],
      pageQ: 0,
      pageNum: 0,
      pageIndex: 0
    }
  },
  props: ['objData'],
  components: {
    Head, Bottom
  },
  watch: {
    objData: {
      immediate: true,
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        this.objDataA = []
        setTimeout(() => {
          console.log('this.objDataA', this.objDataA)
        }, 1000)
        this.init()
      }
    }
  },
  methods: {
    init () {
      // console.log('this.pageQ ', this.pageQ)
      if (this.pageQ < this.objData.length) {
        const a = {
          dataList: []
        }
        this.objDataA.push(a)
        // console.log('this.objDataA', this.objDataA)
        this.initA()
      }
    },
    initA () {
      const that = this
      if (this.pageQ < this.objData.length) {
        this.objDataA[this.pageNum].dataList.push(this.objData[this.pageQ])
        // console.log('this.objDataA', this.objDataA)
        this.pageQ++
        setTimeout(() => {
          that.$forceUpdate()
          const getDivHeightBox = document.getElementById('getDivTop' + String(this.pageNum) + String(this.pageIndex))
          // console.log('getDivHeightBox', getDivHeightBox.offsetTop + getDivHeightBox.offsetHeight)
          this.pageIndex++
          if (getDivHeightBox.offsetTop + getDivHeightBox.offsetHeight > 1500) {
            this.objDataA[this.pageNum].dataList.splice(-1, 1)
            this.pageIndex = 0
            this.pageQ--
            this.pageNum++
            this.init()
          } else {
            this.initA()
          }
        }, 1)
      }
    },
    hexToRgb (hex, opt) {
      // Remove "#" symbol from the beginning of the string
      hex = hex.replace('#', '')
      // Convert each pair of characters to decimal values for red, green, and blue
      var red = parseInt(hex[0] + hex[1], 16)
      var green = parseInt(hex[2] + hex[3], 16)
      var blue = parseInt(hex[4] + hex[5], 16)
      return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')'
    }
  }
}
</script>

<style lang="scss" scoped>
  .analysisReport_box2{
    width: 974px;
    margin: 0 auto;
    .analysisReport_box2_info1{
      margin-top: 83px;
      .analysisReport_box2_info1_title{
        position: relative;
        font-weight: 400;
        font-size: 34px;
        color: #5CAAAA;
        &::after {
          content: "";
          position: absolute;
          bottom: -6px;
          left: 0px;
          width: 34px;
          height: 1px;
          background: #5CAAAA;
          z-index: 1;
        }
      }
 .analysisReport_box2_info1_content{
            font-size: 20px;
            margin-top: 20px;
            color: #474F57;
        }
    }
    .analysisReport_box2_info2{
      margin-top: 88px;
      .analysisReport_box2_info2_title{
        width: 548px;
        height: 60px;
        background: #7FC4C6;
        border-radius: 14px;
        font-weight: 500;
        font-size: 36px;
        color: #FFFFFF;
        line-height: 14px;
      }
      .analysisReport_box2_info2_ul{
        margin-top: 52px;
        .analysisReport_box2_info2_li{
          padding-bottom: 31px;
          margin-bottom: 27px;
          border-bottom: 1px solid #aeb1b4;
          .analysisReport_box2_info2_li_header{
            display: flex;
            align-items: center;
            .analysisReport_box2_info2_li_header_text{
              display: flex;
              align-items: center;
              width: 155px;
              height: 59px;
              background: #FAE8EA;
              border-radius: 15px;
              .analysisReport_box2_info2_li_header_text_d{
                margin: 0 13px 0 23px;
                width: 7px;
                height: 7px;
                background: #D51931;
                border-radius: 50%;
              }
              .analysisReport_box2_info2_li_header_textT{
                font-weight: 400;
                font-size: 24px;
                color: #D51931;
              }
            }
            .analysisReport_box2_info2_li_header_level{
              margin-left: 43px;
              width: 43px;
              height: 41px;
              background: #FAE8EA;
              border-radius: 7px;
              border: 1px solid #D51931;
              font-size: 23px;
              color: #D51931;
            }
          }
          .analysisReport_box2_info2_li_content{
            margin-top: 28px;
            .analysisReport_box2_info2_li_content_li{
              margin-bottom:47px;
              .analysisReport_box2_info2_li_content_li_title{
                font-size: 24px;
                color: #474F57;
                font-weight: 600;
              }
              .analysisReport_box2_info2_li_content_li_text{
                margin-top: 10px;
font-weight: 500;
font-size: 24px;
color: #474F57;
              }
            }
            .analysisReport_box2_info2_li_content_li:nth-last-of-type(1){
              margin-bottom:0px;
            }
          }
        }
      }
    }
  }
</style>
