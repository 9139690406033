<template>
    <div>
        <div class="pageSize">

            <Head bgColor="#5aaca9" bgColorRight="#5aaca9"></Head>
            <div class="way_content">
                <div class="way_title">
                    <div>调节饮食与生活方式</div>
                    <div class="way_title_line"></div>
                </div>
                <div class="way_list">
                    <div class="way_list_left">
                        <div class="text">
                            调节饮食与生活方式
                        </div>
                    </div>
                    <div class="way_list_arrow">
                        <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240807/5B9DEE95C64A4BF19BE7D15023B1ED2E.png"
                            class="wh100">
                    </div>
                    <div class="way_list_right">
                        <div class="way_list_right_item" v-for="(item,index) in showData">
                            <div class="way_list_right_item_title">{{ item.title }}</div>
                            <div class="way_list_right_item_text">
                                {{ item.text }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Head from './head.vue'
import Bottom from './bottom.vue'
export default {
  props: ['objData'],
  components: {
    Head, Bottom
  },
  data () {
    return {
      showData: ''
    }
  },
  watch: {
    objData: {
      immediate: true,
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        const newArr = []
        newValueA.foodAndLefeAdvice.map(v => {
          const strArr = v.split('：')
          newArr.push({
            title: strArr[0],
            text: strArr[1]
          })
        })
        this.showData = newArr
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.way_content {
    width: 974px;
    margin: 0 auto;

    .way_title {
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 35px;
        color: #69B287;
        padding-top: 72px;

        .way_title_line {
            width: 64px;
            height: 1px;
            border: 1px solid #69B287;
            margin-top: 20px;
        }
    }

    .way_list {
        display: flex;
        // justify-content: center;
        align-items: center;
        padding-top: 40px;

        .way_list_left {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 160px;
            height: 160px;
            background: url('https://image.giantgocloud.com/www/ImageMapping/image/20240807/3975A637C5234305A547FA69887820BA.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            .text {
                width: 140px;
                margin: 0 auto;
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 24px;
                color: #474F57;
                line-height: 30px;
            }
        }

        .way_list_arrow {
            width: 16px;
            height: 29px;
            margin: 0 30px;
        }

        .way_list_right {
            .way_list_right_item {
                .way_list_right_item_title {
                    font-family: Source Han Sans CN;
                    font-size: 27px;
                    color: #69B287;
                    padding-top: 40px;
                }

                .way_list_right_item_text {
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 20px;
                    color: #474F57;
                    padding-top: 10px;
                }
            }
        }
    }
}
</style>
