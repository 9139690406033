<template>
  <div class="pageSize home" style="overflow: hidden;">
    <!-- <div class="header_logo">
      <img :src="logoPicture" class="wh100">
    </div>
    <img class="header_title"
      src="https://image.giantgocloud.com/www/ImageMapping/image/20240429/551964BF0DA140BEBA39A04C1D47E5D5.png" />
    <div class="header_sick">
      {{ sickName }}
    </div>
    <div class="analysisReport_home_info1">
      <div class="analysisReport_home_info1_l"></div>
      <div class="analysisReport_home_info1_text">
        <img class="wh100"
          src="https://image.giantgocloud.com/www/ImageMapping/image/20240429/F67E8CE971EB45A383009A45C7E9CA76.png" />
      </div>
      <div class="analysisReport_home_info1_l"></div>
    </div> -->
    <div style="position: relative;">
      <img style="width: 100%;position: relative;z-index: 1;" src="https://image.giantgocloud.com/www/ImageMapping/image/20240930/56B1D7C3DFC9460BA3387DC1B86926CC.png" alt="" />
      <div class="home_title_JZ a_flex" >
        <div v-for="(item,index) in sickName" :key="index" style="margin-left: 3px;">
          {{item}}
        </div>
      </div>
    </div>
    <div class="analysisReport_home_info2">
      <div class="wh100" style='position: relative;'>
        <img class="wh100" style="position: absolute;"
          src="https://image.giantgocloud.com/www/ImageMapping/image/20240429/AE6021DBF62D4AF5B55E1590CCE5E756.png" />
        <div class="analysisReport_home_info2_li" style="top: 487px;">
          <div class="wh100" style='position: relative;'>
            <div class="analysisReport_home_info2_li_left">姓名 / Compellation: </div>
            <div class="analysisReport_home_info2_li_right" v-if="userInfo?.userName">{{ userInfo.userName }}</div>
          </div>
        </div>
        <div class="analysisReport_home_info2_li" style="top: 557px;">
          <div class="wh100" style='position: relative;'>
            <div class="analysisReport_home_info2_li_left">性别 / Gender: </div>
            <div class="analysisReport_home_info2_li_right" v-if="userInfo?.userName">{{ userInfo.sex }}</div>
          </div>
        </div>
        <div class="analysisReport_home_info2_li" style="top: 626px;">
          <div class="wh100" style='position: relative;'>
            <div class="analysisReport_home_info2_li_left">年龄 / Age: </div>
            <div class="analysisReport_home_info2_li_right" v-if="userInfo?.userName">{{ userInfo.userAge }}</div>
          </div>
        </div>
        <div class="analysisReport_home_info2_li" style="top: 696px">
          <div class="wh100" style='position: relative;'>
            <div class="analysisReport_home_info2_li_left">出生日期 / Date Of Birth: </div>
            <div class="analysisReport_home_info2_li_right" v-if="userInfo?.userName">{{ userInfo.userBirthDay }}</div>
          </div>
        </div>
        <div class="analysisReport_home_info2_li" style="top:764px;">
          <div class="wh100" style='position: relative;'>
            <div class="analysisReport_home_info2_li_left">报告日期 / Report Date:</div>
            <div class="analysisReport_home_info2_li_right" v-if="userInfo?.userName">{{ userInfo.checkDate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['userInfo', 'sickName', 'logoPicture'],
  data () {
    return {

    }
  }
}
</script>
<style lang="scss">
.home {
  background-size: 100% 100%;
  position: relative;
  color: #FFFFFF;
  background-color: #fff;

  .header_logo {
    width: 224px;
    height: 144px;
    // background-color: #75D2EC;
    margin: 0 auto;
    margin-top: 102px;
  }

  .header_title {
    width: 780px;
    height: 154px;
    margin: 0 auto;
    margin-top: 40px;
  }

  .header_sick {
    font-family: Source Han Sans CN;
    font-weight: 600;
    font-size: 36px;
    color: #474F57;
    text-align: center;
    padding-top: 40px;
  }

  .analysisReport_home_info1 {
    display: flex;
    justify-content: center;
    margin-top: 38px;

    .analysisReport_home_info1_l {
      width: 3px;
      height: 38px;
      background-color: #474F57;
    }

    .analysisReport_home_info1_text {
      width: 320px;
      height: 37px;
      margin: 0 12px;
    }
  }

  .analysisReport_home_info2 {
    position: absolute;
    width: 992px;
    height: 940px;
    top: 609px;
    left: 92px;

    .analysisReport_home_info2_li {
      display: flex;
      align-items: center;
      position: absolute;
      height: 56px;
      width: 100%;
      background-color: #EFEFEF;
      color: #898989;
      font-size: 24px;

      .analysisReport_home_info2_li_left {
        position: absolute;
        right: 488px;
        top: 12px;
      }

      .analysisReport_home_info2_li_right {
        position: absolute;
        left: 543px;
        top: 12px;
      }
    }
  }
}
.home_title_JZ{
      position: absolute;
      padding:0 20px;
      bottom: 111px;
      left: 50%;
      transform: translateX(-50%);
      min-width: 549px;
      height: 75px;
      background-color: #fff;
      z-index: 1;
      border-radius: 40px;
      font-size: 36px;
      font-weight: 700;
      color:#08a09a;
}
</style>
