<template>
  <!-- <div class="analysisReport_bottom">
    <div class="analysisReport_bottom_li">
      <div class="analysisReport_bottom_li_level a_flex">
        A
      </div>
      <div class="analysisReport_bottom_li_text a_flex">
        最佳
      </div>
    </div>
    <div class="analysisReport_bottom_li">
      <div class="analysisReport_bottom_li_level a_flex">
        B
      </div>
      <div class="analysisReport_bottom_li_text a_flex">
        正常
      </div>
    </div>
    <div class="analysisReport_bottom_li">
      <div class="analysisReport_bottom_li_level a_flex">
        C
      </div>
      <div class="analysisReport_bottom_li_text a_flex">
        风险
      </div>
    </div>
    <div class="analysisReport_bottom_li">
      <div class="analysisReport_bottom_li_level a_flex">
        D
      </div>
      <div class="analysisReport_bottom_li_text a_flex">
        疾病
      </div>
    </div>
  </div> -->
</template>

<script>
export default {
  props: {
    bgColor: {
      type: String, // 类型
      required: false, // 是否必填
      default: '#C8EBED' // 默认值
    }
  },
  data () {
    return {

    }
  }
}
</script>

<style lang='scss' scoped>

  .analysisReport_bottom {
    display: flex;
    position: absolute;
    left: 100px;
    bottom: 59px;
    .analysisReport_bottom_li{
      display:flex;
      align-items: center;
      color: #10854A;
      margin-right: 27px;
      .analysisReport_bottom_li_level{
        background: #C3EFD8;
        font-size: 22px;
        width: 48px;
        height: 27px;
        background: #C3EFD8;
        border-radius: 5px;
        border: 1px solid #10854A;
      }
      .analysisReport_bottom_li_text{
        margin-left: 12px;
        font-size: 18px;
        line-height: 18px;
      }
    }
    .analysisReport_bottom_li:nth-of-type(2){
      color: #5EC692;
      .analysisReport_bottom_li_level{
        background: #EFF9F4;
        border:1px solid #5EC692;
      }
    }
    .analysisReport_bottom_li:nth-of-type(3){
      color: #EEC122;
      .analysisReport_bottom_li_level{
        background: #FEF9E8;
        border:1px solid #EEC122;
      }
    }
    .analysisReport_bottom_li:nth-of-type(4){
      color: #D51931;
      .analysisReport_bottom_li_level{
        background: #FAE8EA;
        border:1px solid #D51931;
      }
    }
  }
</style>
