<template>
    <div class="pageSize main">
        <div class="title_borderground">
            <div class="title_borderground_content1">{{ text }}</div>
            <div class="title_borderground_content2">健康详情</div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      text: ''
    }
  },
  props: ['objData'],
  watch: {
    objData: {
      immediate: true,
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        // console.log('objData', newValueA)
        const isPdf = newValueA.isAccurateOrFunction
        if (isPdf == 1) {
          this.text = newValueA.functionAnalysisList[0].sickName
        } else {
          this.text = newValueA.singleAnalysisInModel.singleAnalysisListForSick.map(v => v.healthTrouble.projectName).join('&')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
    width: 595px;
    height: 841px;
    background: url('https://image.giantgocloud.com/www/ImageMapping/image/20240430/931E7ADEFB724062AE7809C98395687B.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;

    .title_borderground {
        position: absolute;
        bottom: 589px;
        left: 193px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 48px;
        color: #505EA5;
    }
    .title_borderground_content2{
        padding-left:236px;
    }
}
</style>
