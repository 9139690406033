<template>
  <div>
    <template v-if="!showData">
      <div class="pageSize main">
        <Head text="健康管理方案" bgColor="#2baea9"></Head>
        <div style="padding-left: 104px;">
          <div class="title">
            {{ this.productList.length > 0 ? 3 : 2 }}、个性化营养定制方案
          </div>
          <div class="text" style="padding-top: 10px;">
            {{ objData.singleAnalysisInModel?.productModel.adviceText }}
          </div>
          <template v-for="(item, index) in objData.singleAnalysisInModel?.productModel?.customProductList">
            <div class="food" v-if="index != 2">
              <div class="food_num">第{{ index == 0 ? '一' : '二' }}餐</div>
              <div class="food_content" v-for="(item1, index) in item.productsList">
                <div class="food_title">
                  {{ index + 1 }} {{ item1.productName }}
                </div>
                <div class="food_list">
                  <div class="food_left">
                    <div class="food_left_item" v-for="(item2, index2) in item1.effect">
                      <div class="dot"></div>
                      <div class="text_list">{{ item2.effect }}<span v-if="item2.ingredient">:</span>{{ item2.ingredient
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="food_right">
                    <img class="wh100" :src="item1.productPic" alt="">
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="analysisReportPage" style="position: absolute;right: 60px;bottom: 60px;font-size: 20px;color: #000">
          {{ pageNum }} of {{ pageAllNum }}
        </div>
      </div>
    </template>
    <template v-else>
      <div class="pageSize main">
        <Head text="健康管理方案" bgColor="#2baea9"></Head>
        <div style="padding-left: 104px;">
          <div class="title">
            {{ this.productList.length > 0 ? 3 : 2 }}、个性化营养定制方案
          </div>
          <div class="text" style="padding-top: 10px;">
            {{ objData.singleAnalysisInModel?.productModel.adviceText }}
          </div>
          <template v-for="(item, index) in objData.singleAnalysisInModel?.productModel?.customProductList">
            <div class="food" v-if="index == 0">
              <div class="food_num">第{{ index == 0 ? '一' : '二' }}餐</div>
              <template v-for="(item1, index2) in item.productsList">
                <div class="food_content">
                  <div class="food_title">
                    {{ index2 + 1 }} {{ item1.productName }}
                  </div>
                  <div class="food_list">
                    <div class="food_left">
                      <div class="food_left_item" v-for="(item2, index2) in item1.effect">
                        <div class="dot"></div>
                        <div class="text_list">{{ item2.effect }}<span v-if="item2.ingredient">:</span>{{
                          item2.ingredient
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="food_right">
                      <img class="wh100" :src="item1.productPic" alt="">
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
        <div class="analysisReportPage" style="position: absolute;right: 60px;bottom: 60px;font-size: 20px;color: #000">
          {{ pageNum }} of {{ pageAllNum }}
        </div>
      </div>
      <div class="pageSize main">

        <Head text="健康管理方案" bgColor="#2baea9"></Head>
        <div style="padding-left: 104px;padding-top: 100px;">
          <!-- <div class="title">
            3、个性化营养定制方案
          </div>
          <div class="text" style="padding-top: 10px;">
            {{ objData.singleAnalysisInModel?.productModel.adviceText }}
          </div> -->
          <template v-for="(item, index) in objData.singleAnalysisInModel?.productModel?.customProductList">
            <div class="food" v-if="index == 1">
              <div class="food_num">第{{ index == 0 ? '一' : '二' }}餐</div>
              <template v-for="(item1, index2) in item.productsList">
                <div class="food_content">
                  <div class="food_title">
                    {{ index2 + 1 }} {{ item1.productName }}
                  </div>
                  <div class="food_list">
                    <div class="food_left">
                      <div class="food_left_item" v-for="(item2, index2) in item1.effect">
                        <div class="dot"></div>
                        <div class="text_list">{{ item2.effect }}<span v-if="item2.ingredient">:</span>{{
                          item2.ingredient
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="food_right">
                      <img class="wh100" :src="item1.productPic" alt="">
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
        <div class="analysisReportPage" style="position: absolute;right: 60px;bottom: 60px;font-size: 20px;color: #000">
          {{ pageNum +1 }} of {{ pageAllNum }}
        </div>
      </div>
    </template>
  </div>

</template>

<script>
import Head from './head.vue'
import Bottom from './bottom.vue'
export default {
  props: ['objData', 'pageNum', 'pageAllNum'],
  components: {
    Head,
    Bottom
  },
  data () {
    return {
      productList: [],
      productIndex: 3,
      showData: false
    }
  },
  watch: {
    objData: {
      immediate: true,
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        const producNum1 = newValueA.singleAnalysisInModel?.productModel?.customProductList[0].productsList.length
        const producNum2 = newValueA.singleAnalysisInModel?.productModel?.customProductList[1].productsList.length
        const producNum3 = newValueA.singleAnalysisInModel?.productModel?.customProductList[0].productsList.length + newValueA.singleAnalysisInModel?.productModel?.customProductList[1].productsList.length
        if (producNum1 >= 4 || producNum2 >= 4 || producNum3 >= 4) {
          this.showData = true
        } else {
          this.showData = false
        }
        const productList = [
          ...newValueA.singleAnalysisInModel.productModel.selfInnovateProduct.masterProductList,
          ...newValueA.singleAnalysisInModel.productModel.selfInnovateProduct.factorProductList,
          ...newValueA.singleAnalysisInModel.productModel.selfInnovateProduct.otherProductList
        ]
        const productList1 = []
        productList?.map((v, i) => {
          v.groupProductList.map((v1, i1) => {
            if (i1 == 0) {
              productList1.push({
                showTitle: v.showTitle,
                showSickName: v.showSickName,
                productPicture: v1.productPicture,
                effect: v1.effect,
                showProductName: v1.showProductName,
                unBalanceName: v1.unBalanceName,
                showSickName: v1.showSickName
              })
            } else {
              productList1.push({
                showTitle: null,
                showSickName: null,
                productPicture: v1.productPicture,
                effect: v1.effect,
                showProductName: v1.showProductName
              })
            }
          })
        })
        this.productList = this.chunked(productList1)
      }
    }
  },
  methods: {
    chunked (arr) {
      return arr.reduce((accumulator, currentValue, currentIndex) => {
        const chunkIndex = Math.floor(currentIndex / 3)
        if (!accumulator[chunkIndex]) {
          accumulator[chunkIndex] = []
        }
        accumulator[chunkIndex].push(currentValue)
        return accumulator
      }, [])
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  overflow: hidden;

  .title {
    display: flex;
    font-size: 28px;
    color: #474F57;
    padding-top: 40px;
  }

  .text {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 20px;
    color: #474F57;
    padding-right: 118px;
    line-height: 36px;
  }

  .food {
    padding-top: 10px;

    .food_num {
      font-size: 24px;
      color: #33AEAA;
      font-weight: 600;
    }

    .food_content {
      width: 950px;
      min-height: 40px;
      border: 1px solid #efefef;
      margin-top: 20px;
      border-radius: 6px;
      padding-bottom: 20px;

      .food_title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        background-color: #efefef;
        padding-left: 20px;
        font-size: 18px;
        color: #33AEAA;
      }

      .food_list {
        display: flex;
        justify-content: space-between;

        .food_left {
          padding-left: 20px;
          padding-top: 10px;

          .food_left_item {
            display: flex;
            align-items: center;
            line-height: 22px;

            .dot {
              width: 6px;
              height: 6px;
              border: 1px solid #33AEAA;
              border-radius: 3px;
            }

            .text_list {
              color: #474F57;
              font-size: 14px;
              padding-left: 5px;
            }
          }
        }

        .food_right {
          width: 140px;
          height: 140px;
          margin-top: 10px;
          margin-right: 20px;
          border-radius: 10px;
          overflow: hidden;
        }
      }
    }
  }

  .productTitle {
    padding: 30px 0;
    padding-left: 104px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    font-size: 32px;
    color: #2baea9;
  }

  .productContent {
    display: flex;
    justify-content: space-between;
    margin-left: 104px;

    .productContent_left {
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 18px;
      color: #474F57;

      .productContent_left_name {
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 22px;
        color: #474F57;
      }

      .productContent_line {
        margin: 10px 0;
        margin-bottom: 20px;
        width: 600px;
        height: 0.5px;
        border: 0.5px solid #474F57;
        opacity: 0.1;
      }

      .productContent_left_effect {
        display: flex;
        align-items: center;
        padding: 2px 0;

        .effect_img {
          width: 18px;
          height: 18px
        }

        .effect_text {
          padding-left: 10px;
          font-size: 13px;
          color: #474F57;
        }
      }

    }

    .productContent_right {
      width: 379px;
      height: 233px;
      flex-shrink: 0;
      margin-right: 60px;

      .img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.product_name {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 20px;
  color: #474F57;
  padding: 10px 0;
}

.product_name_line {
  margin: 10px 0;
  margin-bottom: 20px;
  width: 600px;
  height: 0.5px;
  border: 0.5px solid #2baea9;
  opacity: 0.1;
}
</style>
