<template>
    <div class="pageSize">
        <Head text="健康管理方案" bgColor="#DDE3F1" bgColorRight="#DDE3F1"></Head>
        <div class="testReport_pages11" style="width: 100%;height: auto;">
          <div class="title">
              <div class="title_left">02</div>
              <div class="title_right">
                  影响你{{ objData.riskAnalysisModel.itemName }}生活方式/因素分析
                  <div class="line"></div>
              </div>
          </div>
          <div class="analysis">
              <div class="analysis_list" v-for="(item,index) in objData.riskAnalysisModel.lifeStyleList">
                  <div class="analysis_list_trg">
                      <img class="imgs"
                          src="https://image.giantgocloud.com/www//ImageMapping/image/20231211/7B376D9C9F7E4615A5CC2CB02039A0E9.png"
                          alt="">
                  </div>
                  <div class="analysis_list_right">
                      <div class="analysis_list_right_list">
                          <div class="analysis_list_right_title">
                              {{ item.projectName }}
                          </div>
                          <div class="analysis_list_right_text">
                              {{ item.description }}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>

    </div>
</template>
<script>
import Head from './head.vue'
export default {
  data () {
    return {

    }
  },
  props: ['objData'],
  components: {
    Head
  }

}
</script>

<style lang="scss" scoped>
.testReport_pages11 {
    .title {
        display: flex;

        .title_left {
            font-family: Bahnschrift;
            font-weight: 400;
            font-size: 114px;
            color: #DFE3E8;
            padding-top: 80px;
        }

        .title_right {
            position: relative;
            font-family: Source Han Sans CN;
            font-weight: 500;
            font-size: 42px;
            color: #505EA5;
            padding-top: 100px;

            .line {
                width: 80px;
                height: 2px;
                background-color: #505EA5;
                margin-top: 20px;
            }
        }
    }

    .analysis {
        padding-left: 200px;

        .analysis_list {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 40px 0;

            .analysis_list_left {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 148px;
                height: 148px;
                margin: 0 40px;
                color: #474F57;
                border: 10px solid #EEC122;
                border-radius: 50%;
                font-size: 28px;
            }

            .analysis_list_trg {
                width: 18px;
                height: 34px;

                .imgs {
                    width: 100%;
                    height: 100%;
                }
            }

            .analysis_list_right {
                flex: 1;
                padding-left: 20px;

                .analysis_list_right_list {
                    .analysis_list_right_title {
                        font-size: 28px;
                        color: #EEC122;
                        padding-top: 10px;
                    }

                    .analysis_list_right_text {
                        padding-top: 10px;
                        color: #474F57;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
</style>
