<template>
  <div v-if="dataObj && dataObjA" class="analysisReport">
    <Home :logoPicture="dataObj.logoPicture" :userInfo="dataObj.userInfo"
      :sickName="dataObj.riskAnalysisModel.itemName" />
    <Cover2 :pageAllNum="pageAllNum" />
    <Cover3 :pageAllNum="pageAllNum" />
    <Pages2 :pageAllNum="pageAllNum" :userInfo="dataObj.userInfo" :objData="dataObj" />
    <Pages2_1 :pageAllNum="pageAllNum" :userInfo="dataObj.userInfo" :objData="dataObj" />
    <Cover4 :pageAllNum="pageAllNum" :objData="dataObj" />
    <Tree :pageAllNum="pageAllNum" :objData="dataObj" />
    <template v-if="dataObj.pdfModelList?.length == 1">
      <pages2A :pageAllNum="pageAllNum" :pageNum="7" :objData="dataObj.pdfModelList[0]" />
      <pages2D :pageAllNum="pageAllNum" :pageNum="8" :objData="dataObj.pdfModelList[0]" />
    </template>
    <template v-if="dataObj.pdfModelList?.length == 2">
      <pages2A :pageAllNum="pageAllNum" :pageNum="7" :objData="dataObj.pdfModelList[0]" />
      <pages2D :pageAllNum="pageAllNum" :pageNum="8" :objData="dataObj.pdfModelList[0]" />
      <pages2A :pageAllNum="pageAllNum" :pageNum="9" :objData="dataObj.pdfModelList[1]" />
      <pages2D :pageAllNum="pageAllNum" :pageNum="10" :objData="dataObj.pdfModelList[1]" />
    </template>
    <Pages5 :pageAllNum="pageAllNum" :pageNum="setNum + 9" :objData="dataObj.singleAnalysisInModel
      .singleAnalysisListForSick[0]" :titleA="dataObj.singleAnalysisInModel
        .singleAnalysisListForSick[0].healthTrouble.projectName + '主题分析'"
      :titleB="'根据你的上传数据分析，以下是以功能医学疾病机制为科学依据对您关注的'+dataObj.singleAnalysisInModel.singleAnalysisListForSick[0].healthTrouble.projectName+'健康主题进行分析的结果'" />
    <div v-if="dataObj.singleAnalysisInModel
      .singleAnalysisListForSick.length > 1">
      <Pages6 :pageAllNum="pageAllNum" :pageNum="setNum + Pages6Num" :objData="dataObj.singleAnalysisInModel
        .singleAnalysisListForSick[1]" :titleA="dataObj.singleAnalysisInModel
          .singleAnalysisListForSick[1].healthTrouble.projectName + '主题分析'"
        :titleB="'根据你的上传数据分析，以下是以功能医学疾病机制为科学依据对您关注的'+dataObj.singleAnalysisInModel.singleAnalysisListForSick[1].healthTrouble.projectName+'健康主题进行分析的结果'" />
    </div>
    <Pages11 @toAllpage="toAllpageF" :pageAllNum="pageAllNum" :pageNum="setNum + 11 + setNumA"
      :stageHealthyManageList="dataObj.stageHealthyManageList" :objData="dataObj" />
    <!-- <Nutrition :pageAllNum="pageAllNum" :pageNum="12" :objData="dataObj" /> -->
    <Pages12 :pageAllNum="pageAllNum" :pageNum="setNum + 13 + setNumA"
      :stageHealthyManageList="dataObj.stageHealthyManageList" :objData="dataObj" />
  </div>
</template>

<script>
import H5Report from '@/api/index'
import axios from 'axios'
import Home from './analysisReport/home.vue'
import Pages2 from './analysisReport/pages2.vue'
import Pages2_1 from './analysisReport/pages2_1.vue'
import Pages3 from './analysisReport/pages3.vue'
import Pages4 from './analysisReport/pages4.vue'
import Pages5 from './analysisReport/pages5.vue'
import Pages6 from './analysisReport/pages6.vue'
import Pages7 from './analysisReport/pages7.vue'
// import Pages8 from './analysisReport/pages8.vue'
import Pages9 from './analysisReport/pages9.vue'
import Pages10 from './analysisReport/pages10.vue'
import Pages11 from './analysisReport/pages11.vue'
import Pages12 from './analysisReport/pages12.vue'
import Tree from './analysisReport/tree.vue'
import Nutrition from './analysisReport/nutrition.vue'
import Intervene from './analysisReport/intervene.vue'
import Cover from './analysisReport/cover.vue'
import CoverA from './analysisReport/cover1.vue'
import Cover2 from './analysisReport/cover2.vue'
import Cover3 from './analysisReport/cover3.vue'
import Cover4 from './analysisReport/cover4.vue'
import Definition from './analysisReport/definition.vue'
import nutritionRecomme from './analysisReport/nutritionRecomme.vue'
import Etiology from './analysisReport/etiology.vue'
import Risk from './analysisReport/risk.vue'
import Symptom from './analysisReport/symptom.vue'
import Complications from './analysisReport/complications.vue'
import AnalysisFun1 from './analysisReport/analysisFun1.vue'
import AnalysisFun2 from './analysisReport/analysisFun2.vue'
import AnalysisFun3 from './analysisReport/analysisFun3.vue'
import Pages17 from './analysisReport/pages17.vue'
import pages2A from './analysisReport/pages2A.vue'
import pages2B from './analysisReport/pages2B.vue'
import pages2C from './analysisReport/pages2C.vue'
import pages2D from './analysisReport/pages2D.vue'
export default {
  components: {
    Home,
    Cover2,
    Cover3,
    Cover4,
    Pages2,
    Pages2_1,
    pages2A,
    pages2B,
    pages2C,
    pages2D,
    Pages3,
    Pages4,
    Pages5,
    Pages6,
    Pages7,
    // Pages8,
    Pages9,
    Pages10,
    Pages11,
    Pages12,
    Tree,
    Nutrition,
    nutritionRecomme,
    Intervene,
    Cover,
    CoverA,
    Definition,
    Etiology,
    Risk,
    Symptom,
    Complications,
    Pages17,
    AnalysisFun1,
    AnalysisFun2,
    AnalysisFun3
  },
  methods: {
    toAllpageF (e) {
      // setTimeout(() => {
      //   let a = 0
      //   for (let i = 0; i < this.dataObj.singleAnalysisInModel.singleAnalysisListForSick.length; i++) {
      //     if (this.dataObj.singleAnalysisInModel.singleAnalysisListForSick[0].factorTroubleList.length >= 7) {
      //       a = a + 1
      //     }
      //   }

      //   this.pageAllNum = e + 11 + a
      //   console.log('this.pageAllNum', this.pageAllNum)
      // }, 10)
    },
    getPageSize () {
      console.log('777777777777777777777')

      setTimeout(() => {
        const analysisReportPage = document.getElementsByClassName('analysisReportPage')
        // console.log('analysisReportPage', analysisReportPage)
        // console.log('analysisReportPage.length', analysisReportPage.length)
        for (let i = 0; i < analysisReportPage.length; i++) {
          // console.log('analysisReportPage[i].innerHTML', analysisReportPage[i].innerHTML)
          analysisReportPage[i].innerHTML = i + 1 + ' of ' + analysisReportPage.length
          // console.log('analysisReportPage[i].innerHTML111', analysisReportPage[i].innerHTML)
        }
        // const getPageSizeTotal = document.getElementsByClassName('pageSize')
        // console.log('getPageSizeTotal111', getPageSizeTotal)
      }, 3000)
    }
  },
  data () {
    return {
      PagesInitData: '',
      dataObj: '',
      dataObjA: '',
      isShow: false,
      treeList: [],
      setNum: 0,
      producNum: 0,
      pageAllNum: 9,
      setNumA: 0,
      Pages6Num: 0
    }
  },
  mounted () {
    const id = this.$query('id')
    axios
      .post(
        // `https://healthingcoretest.giantgocloud.com/api/SceneCheckPage/GetPrecisePdfPageData`,
        `${H5Report}/boardApi/CustomScheme/GetBodyAnalysisForReport`, {
          id
        }
      ).then(res => {
        // console.log('8888888', res.data.data)
        this.dataObj = res.data.data
        // Head组件数据
        window.sessionStorage.setItem('userInfo', JSON.stringify(res.data.data.userInfo))
        // 处理矩阵图数据
        const oldObj = res.data.data
        const newArr = []
        const newArr2 = []
        if (oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList.length == 1) {
          for (let i = 0; i < oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList.length; i++) {
            if (!oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[i].projectColorCode) {
              oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[i].projectColorCode = '#afb1b7'
            }
          }
          if (oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0].unbalanceFactorList) {
            for (let i = 0; i < oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0].unbalanceFactorList
              .length; i++) {
              const a = oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0].unbalanceFactorList[i]
              a.type = 3
              const ifT = true

              newArr.push(a)
            }
          }

          oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorListC = []
          for (let i = 0; i < newArr.length; i++) {
            oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorListC.push(newArr[i])
          }
        }
        if (oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList.length == 2) {
          for (let i = 0; i < oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList.length; i++) {
            if (!oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[i].projectColorCode) {
              oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[i].projectColorCode = '#afb1b7'
            }
          }
          if (oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0].unbalanceFactorList) {
            for (let i = 0; i < oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0].unbalanceFactorList
              .length; i++) {
              const a = oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0].unbalanceFactorList[i]
              a.type = 1
              const ifT = true
              if (oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[1].unbalanceFactorList) {
                for (let q = 0; q < oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[1]
                  .unbalanceFactorList.length; q++) {
                  if (oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[1].unbalanceFactorList[q]
                    .projectName == a.projectName) {
                    a.type = 3
                    break
                  }
                }
              }

              newArr.push(a)
            }
          } else {
            oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0].unbalanceFactorList = []
          }
          if (oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[1].unbalanceFactorList) {
            for (let q = 0; q < oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[1].unbalanceFactorList
              .length; q++) {
              const a = oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[1].unbalanceFactorList[q]
              a.type = 2
              let ifT = true
              if (oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0].unbalanceFactorList) {
                for (let i = 0; i < oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0]
                  .unbalanceFactorList.length; i++) {
                  if (oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[0].unbalanceFactorList[i]
                    .projectName == oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[1]
                    .unbalanceFactorList[q].projectName) {
                    ifT = false
                    break
                  }
                }
              }

              if (ifT) {
                newArr.push(a)
              }
            }
          } else {
            oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorList[1].unbalanceFactorList = []
          }
          oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorListA = []
          oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorListB = []
          oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorListC = []
          for (let i = 0; i < newArr.length; i++) {
            if (newArr[i].type == 1) {
              oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorListA.push(newArr[i])
            } else if (newArr[i].type == 2) {
              oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorListB.push(newArr[i])
            } else if (newArr[i].type == 3) {
              oldObj.singleAnalysisInModel.matrixGraph.unbalanceFactorListC.push(newArr[i])
            }
          }
        }
        this.dataObjA = oldObj.singleAnalysisInModel?.matrixGraph

        // 处理页数
        if (this.dataObj.pdfModelList.length > 1) {
          this.setNum = 2
          this.pageAllNum = this.pageAllNum + 2
        } else {
          this.setNum = 0
        }
        if (this.dataObj.singleAnalysisInModel.singleAnalysisListForSick.length <= 1) {
          this.setNumA = this.setNum - 1
        } else {
          this.setNumA = 0
        }
        const totalNum = this.dataObj.singleAnalysisInModel.singleAnalysisListForSick[0].factorTroubleList.length
        const totalNum2 = this.dataObj.singleAnalysisInModel.singleAnalysisListForSick[0].factorTroubleList.length + this.dataObj.singleAnalysisInModel.singleAnalysisListForSick[0].liveTroubleList.length
        if (totalNum > 7 || totalNum2 > 7) {
          this.pageAllNum = this.pageAllNum + 2
          this.Pages6Num = 11
          this.setNumA = this.setNumA + 1
        } else {
          this.Pages6Num = 10
          this.pageAllNum = this.pageAllNum + 1
        }
        if (this.dataObj.singleAnalysisInModel.singleAnalysisListForSick.length > 1) {
          const totalNum4 = this.dataObj.singleAnalysisInModel.singleAnalysisListForSick[1].factorTroubleList.length
          const totalNum5 = this.dataObj.singleAnalysisInModel.singleAnalysisListForSick[1].factorTroubleList.length + this.dataObj.singleAnalysisInModel.singleAnalysisListForSick[1].liveTroubleList.length
          if (totalNum4 > 7 || totalNum5 > 7) {
            this.pageAllNum = this.pageAllNum + 2
            this.setNumA = this.setNumA + 2
          } else {
            this.pageAllNum = this.pageAllNum + 4
          }
          console.log('totalNum4totalNum4', totalNum4, totalNum5)
        } else {
          this.pageAllNum = this.pageAllNum + 2
        }
        console.log('pageAllNumpageAllNum', '------------', this.pageAllNum)
      })
    this.getPageSize()
  }
}
</script>
<style lang="scss" scoped>
div {
  box-sizing: border-box;
}

.analysisReport {
  width: 1190px;
}

// .pageSize {
//   position: relative;
//   box-sizing: border-box;
//   width: 1190px;
//   height: 1682px;
// }</style>
