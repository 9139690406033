<template>
    <div class="pageSize testReport_pages11">

        <Head text="健康管理方案" bgColor="#E2F1E3" bgColorRight="#E2F1E3"></Head>
        <div class="testReport_pages4_title_box">
            <div class="title">脂肪肝的定义</div>
            <div class="line"></div>
        </div>
        <div class="content">
            <div class="text_content">
                <div class="text_item">
                    非酒精性脂肪肝(NAFLD)是一种与肥胖相关的慢性进展性肝脏疾病，通常是指不饮酒的人的脂肪堆积在肝脏中形成的一种疾病NAFLD
                </div>
                <div class="text_item">
                    非酒精性脂肪肝(NAFLD)是一种与肥胖相关的慢性进展性肝脏疾病，通常是指不饮酒的人的脂肪堆积在肝脏中形成的一种疾病NAFLD
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Head from './head.vue'
export default {
  components: {
    Head
  },
  data () {
    return {

    }
  }
}
</script>

<style lang="scss">

.testReport_pages11 {
    width: 595px;
    height: 842px;
    padding: 0 60px 0 53px;
    position: relative;

    .testReport_pages4_title_box {
        padding-left: 103px;
        padding-top: 60px;

        .title {
            font-family: Source Han Sans CN;
            font-weight: 500;
            font-size: 42px;
            color: #5aaca9;
        }

        .line {
            width: 100px;
            height: 3px;
            margin-top: 30px;
            background-color: #5aaca9;
        }
    }

}

.content {
    width: 997px;
    height: 1086px;
    background: url('https://image.giantgocloud.com/www/ImageMapping/image/20240430/B82F7FA6E98240FD8A7B20703F104608.png') no-repeat;
    background-size: 100% 100%;
    margin-left: 136px;
    margin-top: 259px;

    .text_content {
        padding-top: 327px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        font-size: 24px;
        color: #474F57;
        .text_item{
            padding-left: 87px;
            padding-right: 144px;
            padding-top: 60px;
        }
    }
}
</style>
