<template>
    <div>
        <div class="pageSize main" v-for="(item, index) in productList">
            <Head text="健康管理方案" bgColor="#C8EBED"></Head>
            <div style="padding-left: 104px;">
                <div class="title">
                    <div class="title_left">02</div>
                    <div class="title_right">
                        健康管理方案建议
                        <div class="line"></div>
                        <!-- <div class="number">{{ index + 1 }}</div> -->
                    </div>
                </div>
            </div>
            <template v-for="(item1, index) in item">
                <div class="productTitle"> {{ item1.showTitle }}</div>
                <div class="productContent">
                    <div class="productContent_left">
                        <div class="productContent_left_name">
                            产品名称：{{ item1.showProductName }}
                        </div>
                        <div class="productContent_line"></div>
                        <div class="productContent_left_effect" v-for="(item2, index2) in item1.effect">
                            <div class="effect_img">
                                <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240606/13D5196D44294522A8D00F420E2FE084.png"
                                    alt="" style="width: 100%;height: 100%;">
                            </div>
                            <div class="effect_text">
                                {{ item2.effect }}<span v-if="item2.ingredient">:</span>{{ item2.ingredient }}
                            </div>
                        </div>
                    </div>
                    <div class="productContent_right">
                        <img :src="item1.productPicture" alt="" class="img">
                    </div>
                </div>
            </template>
            <div class="analysisReportPage" style="position: absolute;right: 60px;bottom: 60px;font-size: 20px;color: #000">
                {{pageNum}} of {{pageAllNum}}
               </div>
        </div>
    </div>
</template>

<script>
import Head from './head.vue'
import Bottom from './bottom.vue'
export default {
  props: ['objData', 'pageNum', 'pageAllNum'],
  components: {
    Head,
    Bottom
  },
  data () {
    return {
      productList: [],
      productIndex: '04'
    }
  },
  watch: {
    objData: {
      immediate: true,
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        const selfInnovateProduct = newValueA.singleAnalysisInModel.productModel.selfInnovateProduct
        if (!selfInnovateProduct || selfInnovateProduct.length == 0) {
          this.productIndex = '03'
        } else {
          const productList = [
            ...newValueA.singleAnalysisInModel.productModel.selfInnovateProduct.masterProductList,
            ...newValueA.singleAnalysisInModel.productModel.selfInnovateProduct.factorProductList,
            ...newValueA.singleAnalysisInModel.productModel.selfInnovateProduct.otherProductList
          ]
          if (productList.length == 0) {
            this.productIndex = '03'
          } else {
            this.productIndex = '04'
          }
          const productList1 = []
                    productList?.map((v, i) => {
                      v.groupProductList.map((v1, i1) => {
                        if (i1 == 0) {
                          productList1.push({
                            showTitle: v.showTitle,
                            showSickName: v.showSickName,
                            productPicture: v1.productPicture,
                            effect: v1.effect,
                            showProductName: v1.showProductName,
                            unBalanceName: v1.unBalanceName,
                            showSickName: v1.showSickName
                          })
                        } else {
                          productList1.push({
                            showTitle: null,
                            showSickName: null,
                            productPicture: v1.productPicture,
                            effect: v1.effect,
                            showProductName: v1.showProductName
                          })
                        }
                      })
                    })
                    this.productList = this.chunked(productList1)
                    console.log('productList', this.productList)
        }
      }
    }
  },
  methods: {
    chunked (arr) {
      return arr.reduce((accumulator, currentValue, currentIndex) => {
        const chunkIndex = Math.floor(currentIndex / 3)
        if (!accumulator[chunkIndex]) {
          accumulator[chunkIndex] = []
        }
        accumulator[chunkIndex].push(currentValue)
        return accumulator
      }, [])
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
    overflow: hidden;

    .title {
        display: flex;

        .title_left {
            font-family: Bahnschrift;
            font-weight: 400;
            font-size: 114px;
            color: #DFE3E8;
            padding-top: 80px;
        }

        .title_right {
            position: relative;
            font-family: Source Han Sans CN;
            font-weight: 600;
            font-size: 28px;
            color: #2baea9;
            padding-top: 100px;

            .line {
                width: 80px;
                height: 2px;
                background-color: #2baea9;
                margin-top: 20px;
            }

            .number {
                position: absolute;
                top: 88px;
                left: 178px;
                width: 31px;
                height: 31px;
                background: #9BD3C8;
                border-radius: 5px;
                font-family: Bahnschrift;
                font-weight: 400;
                font-size: 32px;
                color: #FFFFFF;
                text-align: center;
            }
        }
    }

    .text {
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 20px;
        color: #474F57;
        padding-right: 118px;
    }

    .food {
        display: flex;
        justify-content: space-between;

        .food_left {
            .meal {
                position: relative;
                padding-top: 80px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                font-size: 36px;
                color: #2baea9;

                .line {
                    position: absolute;
                    top: 107px;
                    left: 160px;
                    width: 560px;
                    height: 2px;
                    background-color: #2baea9;
                }

                .line1 {
                    position: absolute;
                    top: 107px;
                    left: 307px;
                    width: 378px;
                    height: 2px;
                    background-color: #2baea9;
                }
            }

            .meal_item {
                .meal_item_title {
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 26px;
                    color: #474F57;
                    padding-top: 20px;

                    .line {
                        display: inline-block;
                        width: 60px;
                        height: 2px;
                        background-color: #474F57;
                        margin: 0 20px;
                        margin-bottom: 8px;
                    }
                }

                .meal_item_title_list {
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #474F57;
                    padding-left: 22px;
                    // padding-top: 20px;
                }

                .meal_content {
                    padding-top: 10px;

                    .meal_item_list {
                        display: flex;
                        align-items: center;

                        .meal_item_list_round {
                            flex-shrink: 0;
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            border: 1px solid #2baea9;
                        }

                        .meal_item_list_text {
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            font-size: 18px;
                            color: #474F57;
                            padding-left: 14px
                        }
                    }
                }
            }

            .details {
                padding-top: 50px;

                .details_title {
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    font-size: 36px;
                    color: #2baea9;
                    padding-bottom: 20px;
                }

                .details_list {
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 20px;
                    color: #474F57;
                    line-height: 33px;
                }
            }
        }

        .food_right {
            flex-shrink: 0;
            width: 469px;
            height: 834px;
            margin-right: 10px
        }
    }

    .productTitle {
        padding: 30px 0;
        padding-left: 104px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 32px;
        color: #2baea9;
    }

    .productContent {
        display: flex;
        justify-content: space-between;
        margin-left: 104px;

        .productContent_left {
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 18px;
            color: #474F57;

            .productContent_left_name {
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 22px;
                color: #474F57;
            }

            .productContent_line {
                margin: 10px 0;
                margin-bottom: 20px;
                width: 600px;
                height: 0.5px;
                border: 0.5px solid #2baea9;
                opacity: 0.1;
            }

            .productContent_left_effect {
                display: flex;
                align-items: center;
                padding: 2px 0;

                .effect_img {
                    width: 18px;
                    height: 18px
                }

                .effect_text {
                    padding-left: 10px;
                    font-size: 13px;
                    color: #474F57;
                }
            }

        }

        .productContent_right {
            overflow: hidden;
            width: 229px;
            height: 227px;
            background: #FFFFFF;
            border-radius: 27px;
            border: 4px solid #C6E5DF;
            margin-right: 87px;
            .img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
