<template>
    <div class="testReport_pages13 pageSize">

        <Head text="健康管理方案" bgColorRight="#5aaca9" bgColor="#5aaca9"></Head>
        <div class="testReport_pages13_content">
            <div class="testReport_pages13_content_bg">
                <img class="wh100"
                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240430/7D22EF2609C1476A9887E5B0CBDE7FBF.png"
                    alt="" />
            </div>
            <div style="position: relative;">
                <div class="testReport_pages13_content_text">
                    脂肪肝的风险因素
                </div>
                <div class="testReport_pages13_content_text1">
                    NAFLD在男性中更易发生,并且随着年龄的增长而增加。
                </div>
                <div class="testReport_pages13_content_ul">
                    <div class="testReport_pages13_content_li">
                        <div class='testReport_pages13_content_li_title a_c'>
                            其他可增加NAFLD几率的因素包括：
                        </div>
                        <div class="testReport_pages13_content_li_li">
                            <div class="testReport_pages13_content_li_li_left"></div>
                            <div class="testReport_pages13_content_li_li_text">
                                各种甜饮料,均含工业果糖浆
                            </div>
                        </div>
                        <div class="testReport_pages13_content_li_li">
                            <div class="testReport_pages13_content_li_li_left"></div>
                            <div class="testReport_pages13_content_li_li_text">
                                各种甜饮料,均含工业果糖浆
                            </div>
                        </div>
                        <div class="testReport_pages13_content_li_li">
                            <div class="testReport_pages13_content_li_li_left"></div>
                            <div class="testReport_pages13_content_li_li_text">
                                各种甜饮料,均含工业果糖浆
                            </div>
                        </div>
                    </div>
                    <div class="testReport_pages13_content_li">
                        <div class='testReport_pages13_content_li_title a_c'>
                            其他可增加NAFLD几率的因素包括：
                        </div>
                        <div class="testReport_pages13_content_li_li">
                            <div class="testReport_pages13_content_li_li_left"></div>
                            <div class="testReport_pages13_content_li_li_text">
                                各种甜饮料,均含工业果糖浆
                            </div>
                        </div>
                        <div class="testReport_pages13_content_li_li">
                            <div class="testReport_pages13_content_li_li_left"></div>
                            <div class="testReport_pages13_content_li_li_text">
                                各种甜饮料,均含工业果糖浆
                            </div>
                        </div>
                        <div class="testReport_pages13_content_li_li">
                            <div class="testReport_pages13_content_li_li_left"></div>
                            <div class="testReport_pages13_content_li_li_text">
                                各种甜饮料,均含工业果糖浆
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Head from './head.vue'
export default {
  data () {
    return {
    }
  },
  methods: {
    hexToRgb (hex, opt) {
      // Remove "#" symbol from the beginning of the string
      hex = hex.replace('#', '')
      // Convert each pair of characters to decimal values for red, green, and blue
      var red = parseInt(hex[0] + hex[1], 16)
      var green = parseInt(hex[2] + hex[3], 16)
      var blue = parseInt(hex[4] + hex[5], 16)
      return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')'
    }
  },
  mounted () {

  },
  components: {
    Head
  }
}
</script>

<style lang="scss" scoped>
.testReport_pages13 {
    .testReport_pages13_content {
        overflow: hidden;
        margin-top: 128px;
        margin-left: 226px;
        position: relative;
        width: 780px;
        height: 1367px;

        .testReport_pages13_content_bg {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .testReport_pages13_content_text {
            margin-left: 186px;
            margin-top: 42px;
            font-size: 42px;
            font-weight: 500;
            color: #5aaca9;
        }

        .testReport_pages13_content_text1 {
            margin-left:186px;
            margin-top: 90px;
            font-size: 22px;
            font-weight: 400;
            color: #474F57;
        }

        .testReport_pages13_content_ul {
            margin-top:132px;

            .testReport_pages13_content_li {
                margin-bottom: 100px;

                .testReport_pages13_content_li_title {
                    margin-left: 162px;
                    width: 578px;
                    height: 50px;
                    background: #5aaca9;
                    border-radius: 26px;
                    font-size: 28px;
                    font-weight: 500;
                    color: #FFFFFF;
                    padding-left: 22px;
                }
            }

            .testReport_pages13_content_li_li {
                display: flex;
                align-items: center;
                margin-top: 60px;

                .testReport_pages13_content_li_li_left {
                    margin-right: 60px;
                    margin-left: 102px;
                    width: 22px;
                    height: 22px;
                    background: #FFFFFF;
                    border: 2px solid #5aaca9;
                    border-radius: 50%;
                }

                .testReport_pages13_content_li_li_text {
                    font-size: 22px;
                    font-weight: 400;
                    color: #474F57;
                }
            }
        }
    }
}
</style>
