<template>
  <div class="pageSize">

    <Head text="健康管理方案" bgColor="#C8EBED"></Head>
    <div class="analysisReport_box9">
      <div class="analysisReport_box5_info2">
        <div class="analysisReport_box5_info2_title a_flex" style="display: inline-flex;">
          {{ objData.unbalanceFactorList[0]?.projectName }} <span v-if="objData.unbalanceFactorList.length > 1">&{{
            objData.unbalanceFactorList[1]?.projectName }}分析综合矩阵图</span>
        </div>
        <div style="margin-top: 89px;">
          <div class="analysisReport_box5_T_title" style="">
            <div class="analysisReport_box5_T_title_left">
              <div class="analysisReport_box5_T_title_left_text">
                生活方式诱发因素
              </div>
              <div class="analysisReport_box5_T_title_left_img">
                <img class="wh100"
                  src="https://image.giantgocloud.com/www/ImageMapping/image/20240430/5BABF25142D245929668CF1FA91FA3DD.png"
                  alt="" />
              </div>
            </div>
            <div class="analysisReport_box5_T_title_left" style="width: 280px;">
              <div class="analysisReport_box5_T_title_left_text">
                相关生理失衡诱发因素
              </div>
              <div class="analysisReport_box5_T_title_left_img" style="width: 280px;">
                <img class="wh100"
                  src="https://image.giantgocloud.com/www/ImageMapping/image/20240430/5BABF25142D245929668CF1FA91FA3DD.png"
                  alt="" />
              </div>
            </div>
          </div>
          <div v-if="objData" class="analysisReport_box5_info">
            <div style="height: 100%;display: flex;flex-direction: column;justify-content: center;height:auto;">
              <div class="analysisReport_box5_info_left">
                <div v-for="(item, index) in objData.facotrAnalysisList" :key="index"
                  :style="'color:' + item.projectColorCode + ';border:1px solid ' + item.projectColorCode + ';background:' + hexToRgb(item.projectColorCode, 0.1)"
                  class="analysisReport_box5_info_left_li a_flex">
                  {{ item.projectName }}
                </div>
              </div>
            </div>

            <div class="analysisReport_box5_info_center">
              <div class="analysisReport_box5_info_center_box">
                <div class="analysisReport_box5_info_center_box_img a_flex">
                  <img class="wh100"
                    src="https://image.giantgocloud.com/www//ImageMapping/image/20231211/9F5BF718781C42C8A24D63FD871C2702.png" />
                </div>
                <div class="analysisReport_box5_info_center_box_left_center">
                  <div v-for="(item, index) in objData.unbalanceFactorList" :key="index"
                    class="analysisReport_box5_info_center_box_left_center_radio a_flex">
                    <div v-if="index == 0" style="display: flex;flex-direction: column;border-radius: 50%;"
                      class="wh100 a_flex analysisReport_box5_info_center_box_left_center_radio_box"
                      :style="'color:' + item.projectColorCode + ';border:1px solid ' + item.projectColorCode + ';background:' + hexToRgb(item.projectColorCode, 0.1) + ';'">
                      <div class="analysisReport_box5_info_center_box_left_center_radio_box_leve">
                        {{ item.projectLevel }}
                      </div>
                      <div class="analysisReport_box5_info_center_box_left_center_radio_box_title">
                        {{ item.projectName }}
                      </div>
                    </div>
                    <div v-if="index == 1" style="display: flex;flex-direction: column;border-radius: 50%;"
                      class="wh100 a_flex analysisReport_box5_info_center_box_left_center_radio_box"
                      :style="'color:' + item.projectColorCode + ';border:1px solid ' + item.projectColorCode + ';background:' + hexToRgb(item.projectColorCode, 0.1) + ';'">
                      <div class="analysisReport_box5_info_center_box_left_center_radio_box_title">
                        {{ item.projectName }}
                      </div>
                      <div class="analysisReport_box5_info_center_box_left_center_radio_box_leve">
                        {{ item.projectLevel }}
                      </div>
                    </div>
                  </div>

                </div>
                <div class="analysisReport_box5_info_center_box_img a_flex">
                  <img class="wh100"
                    src="https://image.giantgocloud.com/www//ImageMapping/image/20231211/7B376D9C9F7E4615A5CC2CB02039A0E9.png" />
                </div>
              </div>
            </div>
            <div class="analysisReport_box5_info_right">
              <div v-if="objData.unbalanceFactorList.length == 1" class="analysisReport_box5_info_right_title_box"
                style="justify-content: center;">
                <div v-for="(item, index) in objectData.unbalanceFactorList" :key="index"
                  :style="'color:' + item.projectColorCode" class="analysisReport_box5_info_right_title">
                  <div class="analysisReport_box5_info_right_title_li">
                    {{ item.projectName }}
                  </div>
                  <div v-if="item.projectLevel" class="analysisReport_box5_info_right_title_leve">
                    {{ item.projectLevel }}
                  </div>
                </div>
              </div>
              <div v-if="objData.unbalanceFactorList.length > 1" class="analysisReport_box5_info_right_title_box">
                <div v-for="(item, index) in objData.unbalanceFactorList" :key="index"
                  :style="'color:' + item.projectColorCode" class="analysisReport_box5_info_right_title">
                  <div class="analysisReport_box5_info_right_title_li">
                    {{ item.projectName }}
                  </div>
                  <div v-if="item.projectLevel" class="analysisReport_box5_info_right_title_leve">
                    {{ item.projectLevel }}
                  </div>
                </div>
              </div>
              <div class="analysisReport_box5_info_right_content">
                <div v-for="(item, index) in objData.unbalanceFactorListC"
                  :style="item.type == 3 ? 'width:276px;' : item.type == 1 ? 'justify-content: flex-start;margin-right:36px;' : 'justify-content: flex-end;float:right;'"
                  class="analysisReport_box5_info_right_content_li a_flex">
                  <div v-if="item.type == 3" class="analysisReport_box5_info_right_content_liA"
                    :style="'color:' + item.projectColorCode + ';border:1px solid ' + item.projectColorCode + ';background:' + hexToRgb(item.projectColorCode, 0.1) + ';width:100%;'">
                    <div class="analysisReport_box5_info_right_content_liA_title">
                      {{ item.projectName }}
                    </div>
                    <div class="analysisReport_box5_info_right_content_liA_title_leve">
                      {{ item.projectLevel }}
                    </div>
                  </div>
                  <div v-if="item.type != 3" class="analysisReport_box5_info_right_content_liA"
                    :style="'color:' + item.projectColorCode + ';border:1px solid ' + item.projectColorCode + ';background:' + hexToRgb(item.projectColorCode, 0.1) + ';width:162px;'">
                    <div class="analysisReport_box5_info_right_content_liA_title">
                      {{ item.projectName }}
                    </div>
                    <div class="analysisReport_box5_info_right_content_liA_title_leve">
                      {{ item.projectLevel }}
                    </div>
                  </div>
                </div>
                <div v-for="(item, index) in objData.unbalanceFactorListA"
                  :style="item.type == 3 ? 'width:276px;' : item.type == 1 ? 'justify-content: flex-start;margin-right:36px;' : 'justify-content: flex-end;float:right;'"
                  class="analysisReport_box5_info_right_content_li a_flex">
                  <div v-if="item.type == 3" class="analysisReport_box5_info_right_content_liA"
                    :style="'color:' + item.projectColorCode + ';border:1px solid ' + item.projectColorCode + ';background:' + hexToRgb(item.projectColorCode, 0.1) + ';width:100%;'">
                    <div class="analysisReport_box5_info_right_content_liA_title">
                      {{ item.projectName }}
                    </div>
                    <div class="analysisReport_box5_info_right_content_liA_title_leve">
                      {{ item.projectLevel }}
                    </div>
                  </div>
                  <div v-if="item.type != 3" class="analysisReport_box5_info_right_content_liA"
                    :style="'color:' + item.projectColorCode + ';border:1px solid ' + item.projectColorCode + ';background:' + hexToRgb(item.projectColorCode, 0.1) + ';width:162px;'">
                    <div class="analysisReport_box5_info_right_content_liA_title">
                      {{ item.projectName }}
                    </div>
                    <div class="analysisReport_box5_info_right_content_liA_title_leve">
                      {{ item.projectLevel }}
                    </div>
                  </div>
                </div>
                <div v-for="(item, index) in objData.unbalanceFactorListB"
                  :style="item.type == 3 ? 'width:276px;' : item.type == 1 ? 'justify-content: flex-start;margin-right:36px;' : 'justify-content: flex-end;float:right;'"
                  class="analysisReport_box5_info_right_content_li a_flex">
                  <div v-if="item.type == 3" class="analysisReport_box5_info_right_content_liA"
                    :style="'color:' + item.projectColorCode + ';border:1px solid ' + item.projectColorCode + ';background:' + hexToRgb(item.projectColorCode, 0.1) + ';width:100%;'">
                    <div class="analysisReport_box5_info_right_content_liA_title">
                      {{ item.projectName }}
                    </div>
                    <div class="analysisReport_box5_info_right_content_liA_title_leve">
                      {{ item.projectLevel }}
                    </div>
                  </div>
                  <div v-if="item.type != 3" class="analysisReport_box5_info_right_content_liA"
                    :style="'color:' + item.projectColorCode + ';border:1px solid ' + item.projectColorCode + ';background:' + hexToRgb(item.projectColorCode, 0.1) + ';width:162px;'">
                    <div class="analysisReport_box5_info_right_content_liA_title">
                      {{ item.projectName }}
                    </div>
                    <div class="analysisReport_box5_info_right_content_liA_title_leve">
                      {{ item.projectLevel }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="supplementAdvice">
        <div class="analysisReport_box10_info2_title a_flex" style="display: inline-flex;">
          健康管理补充建议:
        </div>
        <div class="supplementAdvice">
          {{ supplementAdvice }}
        </div>
      </template>
    </div>
    <Bottom />
  </div>
</template>

<script>
import Head from './head.vue'
import Bottom from './bottom.vue'
export default {
  props: ['objData', 'supplementAdvice'],
  components: {
    Head,
    Bottom
  },
  data() {
    return {
      objectData: {
        content: {
          title: '影响您健康隐患的相关失衡因素解析',
          list: [{
            leve: '',
            title: '胰岛素抵抗',
            text: '非酒精性脂肪肝(NAFLD)是一种与肥胖相关的慢性进展性肝脏疾病，通常是指不饮酒的人的脂肪堆积在肝脏中形成的一种疾病。',
            color: '#D51931'
          },
          {
            leve: '',
            title: '胰岛素抵抗',
            text: '非酒精性脂肪肝(NAFLD)是一种与肥胖相关的慢性进展性肝脏疾病，通常是指不饮酒的人的脂肪堆积在肝脏中形成的一种疾病。',
            color: '#D51931'
          },
          {
            title: '胰岛素抵抗',
            text: '非酒精性脂肪肝(NAFLD)是一种与肥胖相关的慢性进展性肝脏疾病，通常是指不饮酒的人的脂肪堆积在肝脏中形成的一种疾病。',
            color: '#F9C716'
          },
          {
            title: '胰岛素抵抗',
            text: '非酒精性脂肪肝(NAFLD)是一种与肥胖相关的慢性进展性肝脏疾病，通常是指不饮酒的人的脂肪堆积在肝脏中形成的一种疾病。',
            color: '#F9C716'
          }
          ]
        },
        leftList: [{
          title: '饮食',
          color: '#F9C716'
        },
        {
          title: '压力',
          color: '#5EC692'
        },
        {
          title: '睡眠',
          color: '#5EC692'
        },
        {
          title: '运动',
          color: '#F9C716'
        }
        ],
        rightList: [{
          title: '脂肪肝',
          leve: '',
          color: '#D51931',
          list: [{
            name: '甲状腺',
            color: '#D51931',
            leve: ''
          },
          {
            name: '慢性肝炎',
            color: '#F9C716',
            leve: ''
          }
          ]
        },
        {
          title: '脂肪肝',
          leve: '',
          color: '#D51931',
          list: [{
            name: '甲状腺',
            color: '#D51931',
            leve: ''
          },
          {
            name: '肝炎',
            color: '#F9C716',
            leve: ''
          }
          ]
        }
        ]
      }
    }
  },
  methods: {
    hexToRgb(hex, opt) {
      // Remove "#" symbol from the beginning of the string
      hex = hex.replace('#', '')
      // Convert each pair of characters to decimal values for red, green, and blue
      var red = parseInt(hex[0] + hex[1], 16)
      var green = parseInt(hex[2] + hex[3], 16)
      var blue = parseInt(hex[4] + hex[5], 16)
      return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')'
    }
  }
}
</script>

<style lang="scss" scoped>
.analysisReport_box9 {
  width: 974px;
  margin: 0 auto;

  .analysisReport_box5_info2 {
    margin-top: 75px;

    .analysisReport_box5_info2_title {
      padding: 13px 50px 12px 51px;
      background: #2baea9;
      border-radius: 14px;
      font-weight: 500;
      font-size: 36px;
      color: #FFFFFF;
    }

    .analysisReport_box5_T_title {
      display: flex;
      justify-content: space-between;

      .analysisReport_box5_T_title_left {
        width: 268px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .analysisReport_box5_T_title_left_text {
          text-align: center;
          color: #474F57;
          font-size: 28px;
        }

        .analysisReport_box5_T_title_left_img {
          margin-top: 13px;
          width: 100%;
          height: 12px;
        }
      }
    }

    .analysisReport_box5_info {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      margin-top: 38px;

      .analysisReport_box5_info_left {
        display: flex;
        flex-direction: column;

        .analysisReport_box5_info_left_li {
          margin-bottom: 26px;
          box-sizing: border-box;
          width: 265px;
          height: 63px;
          border-radius: 12px;
          font-size: 20px;
        }

        .analysisReport_box5_info_left_li:nth-last-of-type(1) {
          margin-bottom: 0px;
        }
      }

      .analysisReport_box5_info_center {
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;

        .analysisReport_box5_info_center_box {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .analysisReport_box5_info_center_box_img {
            width: 26px;
            height: 50px;
          }

          .analysisReport_box5_info_center_box_left_center {
            display: flex;
            flex-direction: column;

            .analysisReport_box5_info_center_box_left_center_radio {
              border-radius: 50%;
              display: flex;
              flex-direction: column;
              box-sizing: border-box;
              width: 209px;
              height: 209px;

              .analysisReport_box5_info_center_box_left_center_radio_box {
                .analysisReport_box5_info_center_box_left_center_radio_box_title {
                  font-size: 29px;
                }

                .analysisReport_box5_info_center_box_left_center_radio_box_leve {
                  font-size: 40px;
                }
              }
            }

            .analysisReport_box5_info_center_box_left_center_radio:nth-of-type(1) {
              position: relative;
              top: 14px;
            }

            .analysisReport_box5_info_center_box_left_center_radio:nth-of-type(2) {
              position: relative;
              top: -14px;
            }
          }
        }
      }

      .analysisReport_box5_info_right {
        width: 280px;

        .analysisReport_box5_info_right_title_box {
          display: flex;
          justify-content: space-between;

          .analysisReport_box5_info_right_title {
            display: flex;
            flex-direction: column;
            align-items: center;

            .analysisReport_box5_info_right_title_li {
              font-size: 26px;
            }

            .analysisReport_box5_info_right_title_leve {
              font-size: 26px;
            }
          }
        }

        .analysisReport_box5_info_right_content {

          // display: flex;
          // flex-direction: column;
          .analysisReport_box5_info_right_content_li {
            display: flex;
            width: 276px;
            box-sizing: border-box;
            padding: 10px 0;
            float: left;
            width: auto;

            .analysisReport_box5_info_right_content_liA {
              border-radius: 12px;
              display: flex;
              flex-direction: column;
              align-items: center;
              min-height: 60px;

              .analysisReport_box5_info_right_content_liA_title {
                line-height: 60px;
                font-size: 26px;
                font-weight: 500;
              }

              .analysisReport_box5_info_right_content_liA_title_leve {
                font-size: 34px;
              }
            }
          }
        }
      }

    }

  }

  .analysisReport_box10_info2_title {
    padding: 13px 50px 12px 51px;
    background: #2baea9;
    border-radius: 14px;
    font-weight: 500;
    font-size: 36px;
    color: #FFFFFF;
  }

  .supplementAdvice {
    padding-left: 20px;
    padding-top: 10px;
    font-size: 24px;
  }

}
</style>
