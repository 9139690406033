<template>
    <div>
      <template v-if="dataList&&dataList.length">
        <div v-for="(itemA,indexA) in dataList" :key="indexA" class="pageSize ">
          <Head text="健康管理方案" bgColor="#DDE3F1" bgColorRight="#DDE3F1"></Head>
            <div class="testReport_pages11" style="width: 100%;height: auto;">
              <div class="title">
                  <div class="title_left">01</div>
                  <div class="title_right">
                      影响你{{ objData.riskAnalysisModel.itemName }}相关器官/因素分析
                      <div class="line"></div>
                  </div>
              </div>
              <div class="contents">
                  <div class="left">
                      <div class="analysisFun2_left_li" v-for="(item, index) in itemA.factorList" :key="index">
                          <template v-if="item.projectLevel == 1 || item.projectLevel == 'A'">
                              <div class="left_title">
                                  <div class="left_title_img" style="border-color: #5EC692;">
                                      <img class="imgs"
                                          src="https://image.giantgocloud.com/www/ImageMapping/image/20240506/F3CAD0D2EFBC4D83881AE7B2755CBC69.png"
                                          alt="">
                                  </div>
                                  <div class="left_title_pross">
                                      <div class="left_title_pross_text">
                                          {{ item.projectName }}
                                      </div>
                                      <div class="left_title_pross_w" style="background-color: #5EC692;width: 120px;"></div>
                                  </div>
                              </div>
                          </template>
                          <template v-else-if="item.projectLevel == 2 || item.projectLevel == 'B'">
                              <div class="left_title">
                                  <div class="left_title_img" style="border-color: #EEC122;">
                                      <img class="imgs"
                                          src="https://image.giantgocloud.com/www/ImageMapping/image/20240506/EB7FBBFF86AF415EAA1C0C350A24848F.png"
                                          alt="">
                                  </div>
                                  <div class="left_title_pross">
                                      <div class="left_title_pross_text">
                                          {{ item.projectName }}
                                      </div>
                                      <div class="left_title_pross_w" style="background-color: #EEC122;width: 160px;"></div>
                                  </div>
                              </div>
                          </template>
                          <template v-else>
                              <div class="left_title">
                                  <div class="left_title_img" style="border-color: #CB8716;">
                                      <img class="imgs"
                                          src="https://image.giantgocloud.com/www/ImageMapping/image/20240506/F2D00D85C333458D93018E1636A849AB.png"
                                          alt="">
                                  </div>
                                  <div class="left_title_pross">
                                      <div class="left_title_pross_text">
                                          {{ item.projectName }}
                                      </div>
                                      <div class="left_title_pross_w" style="background-color: #CB8716;"></div>
                                  </div>
                              </div>
                          </template>
                          <div class="left_title_text">
                              {{ item.description }}
                          </div>
                      </div>
                  </div>
                  <div class="rgight">
                      <img class="imgs"
                          src="https://image.giantgocloud.com/www/ImageMapping/image/20240506/F1EF549763B54484A897775B2D0358AA.png"
                          alt="">
                  </div>
              </div>
            </div>

        </div>
      </template>
    </div>
</template>

<script>
import Head from './head.vue'
export default {
  data () {
    return {
      dataList: [],
      // factorList:[],
      indexLi: 0,
      pageIndex: -1
    }
  },
  props: ['objData'],
  watch: {
    objData: {
      deep: true, // 开启深度监听 专门对付复杂数据类型
      immediate: true,
      handler: function (newValueA, oldValue) {
        console.log('newValueA00', newValueA)
        // console.log(newValueA, 'newValueA11111111111111')
        this.factorList = []
        this.indexLi = 0
        this.pageIndex = -1
        this.init()
      }
    }
  },
  components: {
    Head
  },
  methods: {
    init () {
      setTimeout(() => {
        const AllNum = document.getElementsByClassName('analysisFun2_left_li')
        if (this.objData.riskAnalysisModel.factorList && this.objData.riskAnalysisModel.factorList.length && AllNum.length < this.objData.riskAnalysisModel.factorList.length) {
          const a = {
            factorList: []
          }
          this.dataList.push(a)
          this.pageIndex++
          this.addLi()
        }
      }, 1)
    },
    addLi () {
      console.log('this.indexLi',this.indexLi)
      this.dataList[this.pageIndex].factorList.push(this.objData.riskAnalysisModel.factorList[this.indexLi])
      this.indexLi++
      setTimeout(() => {
        const doms = document.getElementsByClassName('analysisFun2_left_li')
        const getDivHeightBox = doms[doms.length - 1]
        console.log('doms', doms)
        console.log(' getDivHeightBox', JSON.stringify(getDivHeightBox))
        console.log(' getDivHeightBox.offsetTop', getDivHeightBox.offsetTop + getDivHeightBox.offsetHeight)
        if (getDivHeightBox.offsetTop + getDivHeightBox.offsetHeight > 1400) {
          this.dataList[this.pageIndex].factorList.splice(-1, 1)
          this.indexLi--
          setTimeout(()=>{
            this.init()
          },1)
        } else {
          const AllNum = document.getElementsByClassName('analysisFun2_left_li')
          if(AllNum.length < this.objData.riskAnalysisModel.factorList.length){
            this.addLi()
          }

        }
      }, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.testReport_pages11 {
    position: relative;

    .title {
        display: flex;

        .title_left {
            font-family: Bahnschrift;
            font-weight: 400;
            font-size: 114px;
            color: #DFE3E8;
            padding-top: 80px;
        }

        .title_right {
            position: relative;
            font-family: Source Han Sans CN;
            font-weight: 500;
            font-size: 42px;
            color: #505EA5;
            padding-top: 100px;

            .line {
                width: 80px;
                height: 2px;
                background-color: #505EA5;
                margin-top: 20px;
            }
        }
    }

    .contents {
        display: flex;
        justify-content: space-between;

        .left {
            flex: 1;

            .left_title {
                display: flex;
                align-items: center;
                margin-top: 20px;

                .left_title_img {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    border: 2px solid #5EC692;

                    .imgs {
                        width: 32px;
                        height: 72px;
                        margin-left: 32px;
                        margin-top: 12px;
                    }
                }

                .left_title_pross {
                    .left_title_pross_text {
                        font-size: 28px;
                        color: #474F57;
                        padding-left: 16px;
                    }

                    .left_title_pross_w {
                        background-color: #5EC692;
                        width: 240px;
                        height: 48px;
                        border-top-right-radius: 30px;
                        border-bottom-right-radius: 30px;
                        margin-bottom: 30px;
                    }
                }
            }

            .left_title_text {
                color: #474F57;
                font-size: 16px;
                padding-left: 60px;
                padding-top: 6px;
            }
        }

        .rgight {
            width: 404px;
            height: 930px;

            .imgs {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
