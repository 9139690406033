<template>
  <div class="testReport_pages15 pageSize">

    <Head text="健康管理方案" bgColorRight="#E2F1E3" bgColor="#E2F1E3"></Head>
    <div class="testReport_pages4_title_box">
      <div class="title">脂肪肝的并发症</div>
      <div class="line"></div>
    </div>
    <div class="testReport_pages15_content">
      <div class="testReport_pages15_content_title">
        NAFLD 主要并发症是肝硬化这是肝脏后期的癫痕形成(纤维化)造成的
      </div>
      <div class="testReport_pages15_content_box clearfix">
        <div class="testReport_pages15_content_box_img">
          <div class="testReport_pages15_content_box_img_box wh100 clearfix">
            <img class="wh100" style="position: absolute;"
              src="https://image.giantgocloud.com/www/ImageMapping/image/20240430/F980938BDE5249C19A4761A96E093F86.png"
              alt="" />
            <div class="testReport_pages15_content_box_img_box_text_box">
              <div class="testReport_pages15_content_box_img_box_text_title">
                肝硬化可导致:
              </div>
              <div class="testReport_pages15_content_box_img_box_text_title_ul">
                <div class="testReport_pages15_content_box_img_box_text_title_li">
                  腹部积液(肝腹水)
                </div>
                <div class="testReport_pages15_content_box_img_box_text_title_li">
                  食管静脉肿胀,可能会破裂并出血
                </div>
                <div class="testReport_pages15_content_box_img_box_text_title_li">
                  思维混乱、嗜睡和语言不清(肝性脑病
                </div>
                <div class="testReport_pages15_content_box_img_box_text_title_li">
                  肝癌
                </div>
                <div class="testReport_pages15_content_box_img_box_text_title_li">
                  腹部积液(肝腹水)
                </div>
                <div class="testReport_pages15_content_box_img_box_text_title_li">
                  食管静脉肿胀,可能会破裂并出血
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="testReport_pages15_bottom">
        终未期肝衰竭，这意味着肝脏已停止运作。大约20% 的非酒精
        性脂肪性肝炎患者会发展为肝硬化。
      </div>
    </div>
  </div>
</template>

<script>
import Head from './head.vue'
export default {
  data () {
    return {}
  },
  methods: {
  },
  components: {
    Head
  }
}
</script>

<style lang="scss" scoped>
.testReport_pages15 {
  .testReport_pages4_title_box {
    padding-left: 103px;
    padding-top: 60px;

    .title {
      font-family: Source Han Sans CN;
      font-weight: 500;
      font-size: 42px;
      color: #5aaca9;
    }

    .line {
      width: 100px;
      height: 3px;
      margin-top: 30px;
      background-color: #5aaca9;
    }
  }

  .testReport_pages15_content {
    margin-top: 68px;

    .testReport_pages15_content_title {
      margin-left: 138px;
      font-size: 24px;
      font-weight: 400;
      color: #474F57;
      line-height: 60px;
    }

    .testReport_pages15_content_box {
      position: relative;
      margin-top: 56px;

      .testReport_pages15_content_box_img {
        width: 1070px;
        height: 910px;
        position: absolute;
        right: 0;

        .testReport_pages15_content_box_img_box {
          position: relative;

          .testReport_pages15_content_box_img_box_text_box {
            position: relative;
            z-index: 1;
            margin-top: 446px;
            margin-left: 386px;

            .testReport_pages15_content_box_img_box_text_title {
              font-size: 42px;
              font-weight: 500;
              color: #FFFFFF;
            }

            .testReport_pages15_content_box_img_box_text_title_ul {
              margin-top: 56px;

              .testReport_pages15_content_box_img_box_text_title_li {
                font-size: 28px;
                font-weight: 400;
                color: #FFFFFF;
                margin-bottom: 10px;
              }
            }
          }
        }
      }

    }

    .testReport_pages15_bottom {
      width: 636px;
      margin-left: 140px;
      margin-top: 980px;
      font-size: 24px;
      font-weight: 400;
      color: #474F57;
      line-height: 48px;
    }
  }
}
</style>
