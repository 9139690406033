<template>
    <div class="testReport_pages14 pageSize">
      <Head bgColorRight="#E2F1E3" bgColor="#E2F1E3"></Head>
      <div class="testReport_pages14_title_box">
        <div class="testReport_pages14_title_box_title">
            脂肪肝的症状
        </div>
        <div class="testReport_pages14_title_box_title_h">
        </div>
      </div>
      <div class="testReport_pages14_content">
        <div class="testReport_pages14_content_title">
          NAFLD 通常不会引起症状,如果脂肪堆积导致肝功能异常即出现    症状,可能包括
        </div>
        <div class="testReport_pages14_content_box clearfix">
          <div class="testReport_pages14_content_box_left" style="margin-top: 90px;">
              <div class="testReport_pages14_content_box_left_li a_c" style="width: 380px;">
                <div class="testReport_pages14_content_box_left_li_text">
                  {{listData[8].text}}
                </div>
                <div class="testReport_pages14_content_box_left_li_x a_c">
                  <div class="testReport_pages14_content_box_left_li_x_h"></div>
                  <div class="testReport_pages14_content_box_left_li_x_h1" style="width:7px"></div>
                  <div class="testReport_pages14_content_box_left_li_x_y"></div>
                </div>
              </div>
              <div class="testReport_pages14_content_box_left_li a_c" style="width: 330px;margin-top: 12px;">
                <div class="testReport_pages14_content_box_left_li_text">
                  {{listData[6].text}}
                </div>
                <div class="testReport_pages14_content_box_left_li_x a_c">
                  <div class="testReport_pages14_content_box_left_li_x_h"></div>
                  <div class="testReport_pages14_content_box_left_li_x_h1" style="width:38px"></div>
                  <div class="testReport_pages14_content_box_left_li_x_y"></div>
                </div>
              </div>
              <div class="testReport_pages14_content_box_left_li a_c" style="width: 356px;margin-top: 12px;">
                <div class="testReport_pages14_content_box_left_li_text">
                  {{listData[4].text}}
                </div>
                <div class="testReport_pages14_content_box_left_li_x a_c">
                  <div class="testReport_pages14_content_box_left_li_x_h"></div>
                  <div class="testReport_pages14_content_box_left_li_x_h1" style="width:68px"></div>
                  <div class="testReport_pages14_content_box_left_li_x_y"></div>
                </div>
              </div>
              <div class="testReport_pages14_content_box_left_li a_c" style="width: 292px;margin-top: 26px;">
                <div class="testReport_pages14_content_box_left_li_text">
                  {{listData[2].text}}
                </div>
                <div class="testReport_pages14_content_box_left_li_x a_c">
                  <div class="testReport_pages14_content_box_left_li_x_h"></div>
                  <div class="testReport_pages14_content_box_left_li_x_h1" style="width:5px"></div>
                  <div class="testReport_pages14_content_box_left_li_x_y"></div>
                </div>
              </div>
              <div class="testReport_pages14_content_box_left_li a_c" style="width: 330px;margin-top: 12px;">
                <div class="testReport_pages14_content_box_left_li_text">
                  {{listData[0].text}}
                </div>
                <div class="testReport_pages14_content_box_left_li_x a_c">
                  <div class="testReport_pages14_content_box_left_li_x_h"></div>
                  <div class="testReport_pages14_content_box_left_li_x_h1" style="width:54px"></div>
                  <div class="testReport_pages14_content_box_left_li_x_y"></div>
                </div>
              </div>
              <div class="testReport_pages14_content_box_left_li a_c" style="width: 268px;margin-top: 52px;">
                <div class="testReport_pages14_content_box_left_li_text">
                  {{listData[1].text}}
                </div>
                <div class="testReport_pages14_content_box_left_li_x a_c">
                  <div class="testReport_pages14_content_box_left_li_x_h"></div>
                  <div class="testReport_pages14_content_box_left_li_x_h1" style="width:14px"></div>
                  <div class="testReport_pages14_content_box_left_li_x_y"></div>
                </div>
              </div>
              <div class="testReport_pages14_content_box_left_li a_c" style="width: 330px;margin-top: 12px;">
                <div class="testReport_pages14_content_box_left_li_text">
                  {{listData[3].text}}
                </div>
                <div class="testReport_pages14_content_box_left_li_x a_c">
                  <div class="testReport_pages14_content_box_left_li_x_h"></div>
                  <div class="testReport_pages14_content_box_left_li_x_h1" style="width:74px"></div>
                  <div class="testReport_pages14_content_box_left_li_x_y"></div>
                </div>
              </div>
              <div class="testReport_pages14_content_box_left_li a_c" style="width: 328px;margin-top: 18px;">
                <div class="testReport_pages14_content_box_left_li_text">
                  {{listData[5].text}}
                </div>
                <div class="testReport_pages14_content_box_left_li_x a_c">
                  <div class="testReport_pages14_content_box_left_li_x_h"></div>
                  <div class="testReport_pages14_content_box_left_li_x_h1" style="width:12px"></div>
                  <div class="testReport_pages14_content_box_left_li_x_y"></div>
                </div>
              </div>
              <div class="testReport_pages14_content_box_left_li a_c" style="width: 340px;margin-top:24px;">
                <div class="testReport_pages14_content_box_left_li_text">
                  {{listData[7].text}}
                </div>
                <div class="testReport_pages14_content_box_left_li_x a_c">
                  <div class="testReport_pages14_content_box_left_li_x_h"></div>
                  <div class="testReport_pages14_content_box_left_li_x_h1" style="width:19.5px"></div>
                  <div class="testReport_pages14_content_box_left_li_x_y"></div>
                </div>
              </div>
              <div class="testReport_pages14_content_box_left_li a_c" style="width: 330px;margin-top:24px;">
                <div class="testReport_pages14_content_box_left_li_text">
                  {{listData[9].text}}
                </div>
                <div class="testReport_pages14_content_box_left_li_x a_c">
                  <div class="testReport_pages14_content_box_left_li_x_h"></div>
                  <div class="testReport_pages14_content_box_left_li_x_h1" style="width:11px"></div>
                  <div class="testReport_pages14_content_box_left_li_x_y"></div>
                </div>
              </div>
          </div>
          <div class="testReport_pages14_content_box_img">
            <img class="wh100" src="https://image.giantgocloud.com/www/ImageMapping/image/20240430/D9B62807355747809219F5DCEAB5359F.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import Head from './head.vue'
export default {
  data () {
    return {
      listData: [
        {
          type: 0,
          text: '易疲劳'
        },
        {
          type: 1,
          text: '肝肿大'
        },
        {
          type: 2,
          text: '皮肤发黄'
        },
        {
          type: 3,
          text: '皮肤发黄'
        },
        {
          type: 4,
          text: '皮肤发黄'
        },
        {
          type: 5,
          text: '肝肿大'
        },
        {
          type: 6,
          text: '肝肿大'
        },
        {
          type: 7,
          text: '皮肤发黄'
        },
        {
          type: 8,
          text: '肝肿大'
        },
        {
          type: 9,
          text: '皮肤发黄'
        }
      ]
    }
  },
  methods: {
    hexToRgb (hex, opt) {
      // Remove "#" symbol from the beginning of the string
      hex = hex.replace('#', '')
      // Convert each pair of characters to decimal values for red, green, and blue
      var red = parseInt(hex[0] + hex[1], 16)
      var green = parseInt(hex[2] + hex[3], 16)
      var blue = parseInt(hex[4] + hex[5], 16)
      return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')'
    }
  },
  mounted () {

  },
  components: {
    Head
  }
}
</script>

  <style lang="scss" scoped>

    .testReport_pages14{
      background-color: #fff;
      .testReport_pages14_title_box{
        margin-top: 96px;
        .testReport_pages14_title_box_serialNumber{
          padding-left: 146px;
          font-size: 110px;
          font-weight: 400;
          color: #DFE3E8;
        }
        .testReport_pages14_title_box_title{
          padding-left: 144px;
          font-size: 42px;
          font-weight: 500;
          color: #5aaca9;
        }
        .testReport_pages14_title_box_title_h{
          margin-top: 36px;
          width: 74px;
          height: 2px;
          margin-left: 144px;
          background-color: #5aaca9;
        }
      }
      .testReport_pages14_content{
        margin-top: 68px;
        .testReport_pages14_content_title{
          width: 672px;
          margin-left:138px;
          font-size: 24px;
          font-weight: 400;
          color: #474F57;
          line-height: 60px;
        }
        .testReport_pages14_content_box{
          position: relative;
          .testReport_pages14_content_box_left{
            margin-left: 226px;
            .testReport_pages14_content_box_left_li{
              .testReport_pages14_content_box_left_li_text{
                font-size:28px;
                color:#69B287;
                margin-right: 20px;
              }
              .testReport_pages14_content_box_left_li_x{
                position: relative;
                flex:1;
                .testReport_pages14_content_box_left_li_x_h{
                  width:100%;
                  background-color: #69B287;
                  border-bottom: 2px solid #69B297;
                  // height:1px;
                }
                .testReport_pages14_content_box_left_li_x_h1{
                  position: absolute;
                  right:0;
                  background-color: #fff;
                  height:100%;
                  flex:1;
                  height:2px;
                  z-index:1;
                }
                .testReport_pages14_content_box_left_li_x_y{
                  position: absolute;
                  right:-16px;
                  top:50%;
                  transform: translateY(-8px);
                  z-index:1;
                  width: 16px;
                  height: 16px;
                  background: #FFFFFF;
                  border-radius: 50%;
                }
              }
            }
          }
          .testReport_pages14_content_box_img{
            position: absolute;
            top: -60px;
            left:380px;
            width: 484px;
            height:1114px;
          }
        }
      }
    }

  </style>
