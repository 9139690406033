<template>
    <div class="pageSize main">
        <!-- <div class="title_borderground">
            <div class="title_borderground_content1">{{ text }}</div>
            <div class="title_borderground_content2">
                专业健康知识</div>
        </div> -->
        <div class="analysisReportPage" style="position: absolute;right: 60px;bottom: 60px;font-size: 20px;color: #000">
         2 of {{pageAllNum}}
        </div>
    </div>
</template>

<script>
export default {
  props: ['pageAllNum'],
  data () {
    return {
      text: ''
    }
  }
  // props: ['objData'],
  // watch: {
  //   objData: {
  //     immediate: true,
  //     deep: true, // 开启深度监听 专门对付复杂数据类型
  //     handler: function (newValueA, oldValue) {
  //       // console.log('objData', newValueA)
  //       const isPdf = newValueA.isAccurateOrFunction
  //       if (isPdf == 1) {
  //         this.text = newValueA.functionAnalysisList[0].sickName
  //       } else {
  //         this.text = newValueA.singleAnalysisInModel.singleAnalysisListForSick.map(v => v.healthTrouble.projectName).join('&')
  //       }
  //     }
  //   }
  // }
}
</script>

<style lang="scss" scoped>

.main {
    background: url('https://image.giantgocloud.com/www/ImageMapping/image/20240930/DAAABD5FDBC3418590271E5A52E6E371.jpg') no-repeat;
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;

    .title_borderground {
        position: absolute;
        bottom: 589px;
        left: 152px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 48px;
        color: #2bafaa;
    }

    .title_borderground_content1 {
        padding-left: 30px;
    }

    .title_borderground_content2 {
        padding-left: 140px;
    }
}
</style>
