<template>
  <div class="pageSize">
    <Head text="健康管理方案" bgColor="#C8EBED"></Head>
    <div class="analysisReport_box6">
      <div class="analysisReport_box6_title">
        {{titleA}}
      </div>
      <div v-if="objData?.liveTroubleList&&objData.liveTroubleList&&objData.liveTroubleList.length" class="_ul">
      <div  v-for="(item,index) in objData.liveTroubleList" :key="index" class="analysisReport_box6_li">
        <div v-if="item.projectLevel" :style="`background:${hexToRgb(item.projectColorCode,0.1)};color:${item.projectColorCode};border-color:${item.projectColorCode}`" class="analysisReport_box6_li_level a_flex">
          {{item.projectLevel}}
        </div>
        <div class="analysisReport_box6_li_level_text">
              <span :style="`color: ${item.projectColorCode};`">{{item.projectName}}:</span> {{item.description}}
        </div>
      </div>
      </div>
      <div v-else class="analysisReport_box6_ul" style="display: flex;align-items: center;height:auto">
        <div class="analysisReport_box5_content_info_ul1_img">
          <img class="wh100"  src="https://image.giantgocloud.com/www/ImageMapping/image/20240517/2E1BB432802746129C6C41AF1E521347.png" />
        </div>
        <div class="analysisReport_box5_content_info_ul1_text">
          生活方式良好，请继续保持～
        </div>
      </div>
    </div>
    <Bottom />
  </div>
</template>
 <!-- 肝脏功能: 肝脏是脂代谢的主要器官，肝脏代谢解毒能力下降可影响人 -->
<script>
import Head from './head.vue'
import Bottom from './bottom.vue'
export default {
  props: ['objData', 'titleA'],
  components: {
    Head,
    Bottom
  },
  methods: {
    hexToRgb (hex, opt) {
      // Remove "#" symbol from the beginning of the string
      hex = hex.replace('#', '')
      // Convert each pair of characters to decimal values for red, green, and blue
      var red = parseInt(hex[0] + hex[1], 16)
      var green = parseInt(hex[2] + hex[3], 16)
      var blue = parseInt(hex[4] + hex[5], 16)
      return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')'
    }
  }
}
</script>

<style lang="scss" scoped>
  .analysisReport_box6 {
    margin-left: 102px;
    width: 963px;

    .analysisReport_box6_title {
      margin-top: 30px;
      font-weight: 400;
      font-size: 34px;
      color: #2baea9;
    }

    .analysisReport_box6_ul {
      margin-top: 55px;
      height:800px;
      .analysisReport_box5_content_info_ul1_img{
        width:35px;
        height: 31px;
      }
      .analysisReport_box5_content_info_ul1_text{
        margin-left: 16px;
        font-weight: 400;
        font-size: 24px;
        color: #474F57;
      }
      // background-color: antiquewhite;
      .analysisReport_box6_li {

        display: flex;
        margin-bottom: 10px;
        .analysisReport_box6_li_level{
          position: relative;
          top: 3px;
          flex-shrink: 0;
          width:30px;
          height: 28px;
          border-radius: 10px;
          border: 1px solid #D51931;
          background-color: #FAE8EA;
          margin-right: 15px;
          font-size: 16px;
        }
        .analysisReport_box6_li_level_text{
          font-size: 24px;
        }
      }
    }
  }
</style>
