<template>
  <div class="pageSize">
    <Head text="健康管理方案" bgColor="#5aaca9"></Head>
    <div class="analysisReport_box11">
      <!-- <template v-for="(item, index) in productsList" v-if="showData">
        <div class="food" v-if="item">
          <div class="food_num">第三餐</div>
          <template v-for="(item1, index2) in item.productsList">
            <div class="food_content">
              <div class="food_title">
                {{ index2 + 1 }} {{ item1.productName }}
              </div>
              <div class="food_list">
                <div class="food_left">
                  <div class="food_left_item" v-for="(item2, index2) in item1.effect">
                    <div class="dot"></div>
                    <div class="text_list">{{ item2.effect }}<span v-if="item2.ingredient">:</span>{{
                      item2.ingredient
                    }}
                    </div>
                  </div>
                </div>
                <div class="food_right">
                  <img class="wh100" :src="item1.productPic" alt="">
                </div>
              </div>
            </div>
          </template>

        </div>
      </template> -->
      <div style="font-size: 26px;color: #475057;margin-top: 40px;">
        {{ this.selfFoodList.length > 0 ? 4 : 3 }}、饮食建议
      </div>
      <div style="display: flex;justify-content: space-between;">
        <div v-for="(item, index) in objData.singleAnalysisInModel.foodList" :key="index"
          class="analysisReport_box11_content" :style="objData.length == 1 ? 'width:100%;' : 'width:47%;'">
          <div class="analysisReport_box11_content_title">
            <div style="font-size: 40%;">
              {{ item.foodName }}
            </div>
            <div class="a_flex"
              style="width: 30px;height: 30px;border-radius: 50%;color: #fff;background-color: #5aaca9;margin-left: 30px;">
              {{ index + 1 }}
            </div>
          </div>
          <div class="analysisReport_box11_content_img a_flex" style="overflow: hidden;">
            <img style="width: 100%;" :src="item.foodPicture" alt="" />
          </div>
          <div class="analysisReport_box11_content_ul">
            <div class="analysisReport_box11_content_li">
              <div class="analysisReport_box11_content_li_title">
                材料:
              </div>
              <div class="analysisReport_box11_content_li_text">
                {{ item.foodMaterial }}
              </div>
            </div>

            <div class="analysisReport_box11_content_li">
              <div class="analysisReport_box11_content_li_title">
                做法:
              </div>
              <div class="analysisReport_box11_content_li_text">
                <div v-html="item.foodMethod"></div>
              </div>
            </div>
            <div class="analysisReport_box11_content_li">
              <div class="analysisReport_box11_content_li_title">
                注意事项:
              </div>
              <div class="analysisReport_box11_content_li_text">
                {{ item.foodAttention }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="analysisReportPage" style="position: absolute;right: 60px;bottom: 60px;font-size: 20px;color: #000">
      {{ pageAllNum }} of {{ pageAllNum }}
    </div>
    <!-- <Bottom /> -->
  </div>
</template>

<script>
import Head from './head.vue'
import Bottom from './bottom.vue'
export default {
  props: ['objData', 'stageHealthyManageList', 'pageNum', 'pageAllNum'],
  data () {
    return {
      showData: false,
      productsList: [],
      selfFoodList: []
    }
  },
  methods: {
    chunked (arr) {
      return arr.reduce((accumulator, currentValue, currentIndex) => {
        const chunkIndex = Math.floor(currentIndex / 3)
        if (!accumulator[chunkIndex]) {
          accumulator[chunkIndex] = []
        }
        accumulator[chunkIndex].push(currentValue)
        return accumulator
      }, [])
    }
  },
  watch: {
    objData: {
      immediate: true,
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        const productsList = newValueA.singleAnalysisInModel?.productModel?.customProductList.length
        if (productsList == 3) {
          this.showData = true
          this.productsList = [newValueA.singleAnalysisInModel?.productModel?.customProductList[2]]
        }

        // 下标
        const productList3 = [
          ...newValueA.singleAnalysisInModel.productModel.selfInnovateProduct.masterProductList,
          ...newValueA.singleAnalysisInModel.productModel.selfInnovateProduct.factorProductList,
          ...newValueA.singleAnalysisInModel.productModel.selfInnovateProduct.otherProductList
        ]
        // if (productList.length == 0) {
        //   this.showData = true
        // } else {
        //   this.showData = false
        // }
        const productList1 = []
        productList3?.map((v, i) => {
          v.groupProductList.map((v1, i1) => {
            if (i1 == 0) {
              productList1.push({
                showTitle: v.showTitle,
                showSickName: v.showSickName,
                productPicture: v1.productPicture,
                effect: v1.effect,
                showProductName: v1.showProductName,
                unBalanceName: v1.unBalanceName,
                showSickName: v1.showSickName
              })
            } else {
              productList1.push({
                showTitle: null,
                showSickName: null,
                productPicture: v1.productPicture,
                effect: v1.effect,
                showProductName: v1.showProductName
              })
            }
          })
        })
        this.selfFoodList = this.chunked(productList1)
      }
    }
  },
  components: {
    Head,
    Bottom
  }
}
</script>

<style lang="scss" scoped>
.analysisReport_box11 {
  width: 974px;
  margin: 0 auto;

  .analysisReport_box11_title {
    display: flex;
    align-items: center;
    margin-top: 65px;

    .analysisReport_box11_title_num {
      font-family: Bahnschrift;
      font-weight: 400;
      font-size: 114px;
      color: #DFE3E8;
    }

    .analysisReport_box11_title_text {
      position: relative;
      top: -15px;
      margin-left: 15px;
      font-weight: 500;
      font-size: 42px;
      color: #5aaca9;

      &::after {
        content: "";
        position: absolute;
        bottom: -24px;
        left: 0px;
        width: 77px;
        height: 2px;
        background: #5aaca9;
        z-index: 1;
      }
    }

  }

  .analysisReport_box11_content {
    margin-top: 20px;

    .analysisReport_box11_content_title {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding: 3px 20px 3px 25px;
      border-radius: 0 40px 40px 0;
      background-color: #e0f4f3;
      font-weight: 500;
      font-size: 36px;
      color: #5aaca9;
    }

    .analysisReport_box11_content_img {
      margin-top: 43px;
      width: 100%;
      height: 250px;
    }

    .analysisReport_box11_content_ul {
      margin-top: 26px;

      .analysisReport_box11_content_li {
        margin-bottom: 15px;

        .analysisReport_box11_content_li_title {
          position: relative;
          font-size: 18px;
          color: #5aaca9;

          &::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 0px;
            width: 77px;
            height: 3px;
            background: #5aaca9;
            z-index: 1;
          }
        }

        .analysisReport_box11_content_li_text {
          margin-top: 15px;
          font-size: 16px;
          color: #474F57;
        }
      }
    }
  }
}

.analysisReport_box11_S6_ul {
  margin-top: 20px;

  .analysisReport_box11_S6_li {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 20px;

    .analysisReport_box11_S6_li_A {
      display: flex;
      align-items: center;
      width: 200px;
      height: 50px;
      margin-right: 10px;
    }

    .analysisReport_box11_S6_li_B {
      display: flex;
      align-items: center;
      width: 230px;
      height: 59px;
      border-radius: 15px;
      border: 1px solid red;
      margin-right: 10px;

      .analysisReport_box11_S6_li_B_d {
        margin: 0 13px 0 23px;
        width: 7px;
        height: 7px;
        background: #0d1720;
        border-radius: 50%;
      }
    }
  }
}

.food {
  padding-top: 10px;

  .food_num {
    font-size: 24px;
    color: #33AEAA;
    font-weight: 600;
  }

  .food_content {
    width: 950px;
    min-height: 40px;
    border: 1px solid #efefef;
    margin-top: 20px;
    border-radius: 6px;
    padding-bottom: 20px;

    .food_title {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      background-color: #efefef;
      padding-left: 20px;
      font-size: 18px;
      color: #33AEAA;
    }

    .food_list {
      display: flex;
      justify-content: space-between;

      .food_left {
        padding-left: 20px;
        padding-top: 10px;

        .food_left_item {
          display: flex;
          align-items: center;
          line-height: 22px;

          .dot {
            width: 6px;
            height: 6px;
            border: 1px solid #33AEAA;
            border-radius: 3px;
          }

          .text_list {
            color: #474F57;
            font-size: 14px;
            padding-left: 5px;
          }
        }
      }

      .food_right {
        width: 140px;
        height: 140px;
        margin-top: 10px;
        margin-right: 20px;
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }
}
</style>
