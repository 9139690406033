<template>
    <div class="pageSize testReport_pages11">

        <Head text="健康管理方案" bgColor="#DDE3F1" bgColorRight="#DDE3F1"></Head>
        <div class="title">
            <div class="title_left">01</div>
            <div class="title_right">
                {{ objData.functionAnalysisList[0]?.functionAnalysis?.organName }}功能分析
                <div class="line"></div>
            </div>
        </div>
        <div class="contents">
            <div class="left">
                <template v-if="objData.functionAnalysisList[0]?.functionAnalysis?.colorLevel == 1">
                    <div class="left_title">
                        <div class="left_title_img" style="border-color: #5EC692;">
                            <img class="imgs"
                                src="https://image.giantgocloud.com/www/ImageMapping/image/20240506/F3CAD0D2EFBC4D83881AE7B2755CBC69.png"
                                alt="">
                        </div>
                        <div class="left_title_pross">
                            <div class="left_title_pross_text">
                                {{ objData.functionAnalysisList[0]?.functionAnalysis?.organName }}
                            </div>
                            <div class="left_title_pross_w" style="background-color: #5EC692;"></div>
                        </div>
                    </div>
                </template>
                <template v-else-if="objData.functionAnalysisList[0]?.functionAnalysis?.colorLevel == 2">
                    <div class="left_title">
                        <div class="left_title_img" style="border-color: #EEC122;">
                            <img class="imgs"
                                src="https://image.giantgocloud.com/www/ImageMapping/image/20240506/EB7FBBFF86AF415EAA1C0C350A24848F.png"
                                alt="">
                        </div>
                        <div class="left_title_pross">
                            <div class="left_title_pross_text">
                                {{ objData.functionAnalysisList[0]?.functionAnalysis?.organName }}
                            </div>
                            <div class="left_title_pross_w" style="background-color: #EEC122;"></div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="left_title">
                        <div class="left_title_img">
                            <img class="imgs"
                                src="https://image.giantgocloud.com/www/ImageMapping/image/20240506/F2D00D85C333458D93018E1636A849AB.png"
                                alt="">
                        </div>
                        <div class="left_title_pross">
                            <div class="left_title_pross_text">
                                {{ objData.functionAnalysisList[0]?.functionAnalysis?.organName }}
                            </div>
                            <div class="left_title_pross_w"></div>
                        </div>
                    </div>
                </template>
                <div class="left_title_text">
                    {{ objData.functionAnalysisList[0]?.functionAnalysis?.description }}
                </div>
                <div class="left_title_intestine">
                    <img class="imgs"
                        src="https://image.giantgocloud.com/www/ImageMapping/image/20240506/852B9F81F1344D079625F6838FBDBA42.png"
                        alt="">
                </div>
            </div>
            <div class="rgight">
                <img class="imgs"
                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240506/F1EF549763B54484A897775B2D0358AA.png"
                    alt="">
            </div>
        </div>
    </div>
</template>

<script>
import Head from "./head.vue"
// import Head from '/View/reportPdf/testReport/head.vue'
export default {
    data() {
        return {
            obj1: ''
        }
    },
    components: {
        Head,
    },
    props: ['objData'],
    watch: {
        PagesInitData: {
            deep: true, // 开启深度监听 专门对付复杂数据类型
            handler: function (newValueA, oldValue) {
                console.log(newValueA, oldValue)


            }
        }
    }
}
</script>

<style lang="scss" scoped>
.testReport_pages11 {
    position: relative;

    .title {
        display: flex;

        .title_left {
            font-family: Bahnschrift;
            font-weight: 400;
            font-size: 114px;
            color: #DFE3E8;
            padding-top: 80px;
        }

        .title_right {
            position: relative;
            font-family: Source Han Sans CN;
            font-weight: 500;
            font-size: 42px;
            color: #505EA5;
            padding-top: 100px;

            .line {
                width: 80px;
                height: 2px;
                background-color: #505EA5;
                margin-top: 20px;
            }
        }
    }

    .contents {
        display: flex;
        justify-content: space-between;
        padding-top: 120px;

        .left {
            flex: 1;
            margin-left: 120px;

            .left_title {
                display: flex;
                align-items: center;

                .left_title_img {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    border: 2px solid #CB8716;

                    .imgs {
                        width: 32px;
                        height: 72px;
                        margin-left: 32px;
                        margin-top: 12px;
                    }
                }

                .left_title_pross {
                    .left_title_pross_text {
                        font-size: 28px;
                        color: #474F57;
                        padding-left: 16px;
                    }

                    .left_title_pross_w {
                        background-color: #CB8716;
                        width: 200px;
                        height: 48px;
                        border-top-right-radius: 30px;
                        border-bottom-right-radius: 30px;
                    }
                }
            }

            .left_title_text {
                color: #474F57;
                font-size: 24px;
                padding-left: 60px;
                padding-top: 20px;
            }

            .left_title_intestine {
                margin-top: 80px;
                margin-left: 120px;
                width: 440px;
                height: 440px;

                .imgs {
                    width: 100%;
                    height: 100%;
                }
            }

        }

        .rgight {
            width: 418px;
            min-height: 800px;

            .imgs {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
