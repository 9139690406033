<template>
  <div class="pageSize">
    <img class="wh100"  src="https://image.giantgocloud.com/www/ImageMapping/image/20240429/85B012E8C94C4AAE867FBFCA83530B0B.png" />
    <div class="analysisReport_pages4_box">
      <div>{{ text }}</div>
      <div style="text-align: right;">健康管理方案</div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
        return {
            text: ''
        }
    },
    props: ['objData'],
    watch: {
        objData: {
            immediate: true,
            deep: true, // 开启深度监听 专门对付复杂数据类型
            handler: function (newValueA, oldValue) {
                // console.log('objData', newValueA)
                let isPdf = newValueA.isAccurateOrFunction
                if (isPdf == 1) {
                    this.text = newValueA.functionAnalysisList[0].sickName
                } else {
                    this.text = newValueA.singleAnalysisInModel.singleAnalysisListForSick.map(v => v.healthTrouble.projectName).join('&')
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.analysisReport_pages4_box{
  position: absolute;
  top: 1006px;
  left: 159px;
  font-size: 48px;
  color: #2baea9;
}
</style>
