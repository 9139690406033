<template>
  <div>
    <div class="pageSize">
      <Head bgColor="#5aaca9" bgColorRight="#5aaca9"></Head>
      <div class="way_content">
        <div class="mechanism">
          <img :src="showData.sickPicture" class="wh100">
        </div>
      </div>
      <div class="analysisReportPage" style="position: absolute;right: 60px;bottom: 60px;font-size: 20px;color: #000">
       {{pageNum}} of {{pageAllNum}}
      </div>
    </div>
  </div>
</template>
<script>
import Head from './head.vue'
import Bottom from './bottom.vue'
export default {
  props: ['objData', 'userInfo', 'pageNum', 'pageAllNum'],
  components: {
    Head, Bottom
  },
  data () {
    return {
      showData: ''
    }
  },
  watch: {
    objData: {
      immediate: true,
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        // console.log('newValueA', newValueA)
        this.showData = newValueA
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.way_content {
  width: 974px;
  margin: 0 auto;

  .way_title {
    font-family: Source Han Sans CN;
    font-weight: 500;
    font-size: 35px;
    color: #69B287;
    padding-top: 72px;

    .way_title_line {
      width: 64px;
      height: 1px;
      border: 1px solid #69B287;
      margin-top: 20px;
    }
  }
}

  .mechanism {
    width: 900px;
    height: 1359px;
    margin-left: 300px;
    margin: 0 auto;
    margin-top: 50px;
  }
//   .mechanism {
//     width: 1300px;
//     height: 1359px;
//     margin-left: 300px;
//     margin: 0 auto;
//   }
// }
</style>
