<template>
    <div class="pageSize testReport_pages12 ">

        <Head bgColorRight="#E2F1E3" bgColor="#E2F1E3"></Head>
        <div class="testReport_pages4_title_box">
            <div class="title">脂肪肝的病因</div>
            <div class="line"></div>
        </div>
        <div class="testReport_pages12_content" style="width: 100%;">
            <div class="testReport_pages12_content_title">
                当肝脏不能分解脂肪时，它们就在肝组织中堆积起来形成脂肪肝。
            </div>
            <div class="testReport_pages12_contentA">
                <div class="testReport_pages12_content_left">
                    <div class="testReport_pages12_content_left_li">
                        <div class="testReport_pages12_content_left_li_left a_flex">
                            01
                        </div>
                        <div class="testReport_pages12_content_left_li_right">
                            <div class="testReport_pages12_content_left_li_right_title">
                                胃酸和消化液的失衡可导致溃疡，这可能是由于：
                            </div>
                            <div class="testReport_pages12_content_left_li_right_text">
                                许多研究表明,果糖促进肝脏中新脂肪分子形成，阻断现有脂肪分解，刺激自由基生成，引起胰岛素抵抗。越来越多的肥胖患者，与长期消费各
                            </div>
                        </div>
                    </div>
                    <div class="testReport_pages12_content_left_li">
                        <div class="testReport_pages12_content_left_li_left a_flex">
                            02
                        </div>
                        <div class="testReport_pages12_content_left_li_right">
                            <div class="testReport_pages12_content_left_li_right_title">
                                胃酸和消化液的失衡可导致溃疡，这可能是由于：
                            </div>
                            <div class="testReport_pages12_content_left_li_right_text">
                                <div>
                                    许多研究表明,果糖促进肝脏中新脂肪分子形成，阻断现有脂肪分解，刺激自由基生成，引起胰岛素抵抗。越来越多的肥胖患者，与长期消费各;
                                    许多研究表明,果糖促进肝脏中新脂肪分子形成，阻断现有脂肪分解，刺激自由基生成，引起胰岛素抵抗。越来越多的肥胖患者，与长期消费各
                                    许多研究表明,果糖促进肝脏中新脂肪分子形成，阻断现有脂肪分解，刺激自由基生成，引起胰岛素抵抗。越来越多的肥胖患者，与长期消费各
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="testReport_pages12_content_left_li">
                        <div class="testReport_pages12_content_left_li_left a_flex">
                            03
                        </div>
                        <div class="testReport_pages12_content_left_li_right">
                            <div class="testReport_pages12_content_left_li_right_title">
                                胃酸和消化液的失衡可导致溃疡，这可能是由于：
                            </div>
                            <div class="testReport_pages12_content_left_li_right_text">
                                <div>
                                    许多研究表明,果糖促进肝脏中新脂肪分子形成，阻断现有脂肪分解，刺激自由基生成，引起胰岛素抵抗。越来越多的肥胖患者，与长期消费各
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="testReport_pages12_content_right">
                    <div class="testReport_pages12_content_right_img">
                        <img class="wh100"
                            src="https://image.giantgocloud.com/www/ImageMapping/image/20240430/B8D9765ACEDB4A8EB434E08E94773586.png"
                            alt="" />
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Head from './head.vue'
export default {
  data () {
    return {

    }
  },
  methods: {
  },
  mounted () {

  },
  components: {
    Head
  }
}
</script>

<style lang="scss" scoped>
.testReport_pages12 {
    .testReport_pages4_title_box {
        padding-left: 103px;
        padding-top: 60px;

        .title {
            font-family: Source Han Sans CN;
            font-weight: 500;
            font-size: 42px;
            color: #5aaca9;
        }

        .line {
            width: 100px;
            height: 3px;
            margin-top: 30px;
            background-color: #5aaca9;
        }
    }

    .testReport_pages12_content {
        margin-top: 34px;
        padding-left: 96px;

        .testReport_pages12_content_title {
            padding: 60px 0;
            font-family: Source Han Sans SC;
            font-weight: 400;
            font-size: 24px;
            color: #474F57;
        }

        .testReport_pages12_contentA {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;

            .testReport_pages12_content_left {
                .testReport_pages12_content_left_li {
                    display: flex;
                    margin-bottom: 25px;

                    .testReport_pages12_content_left_li_left {
                        display: flex;
                        flex-shrink: 0;
                        width: 78px;
                        height: 78px;
                        background: #5aaca9;
                        border-radius: 50%;
                        font-family: Bahnschrift;
                        font-weight: 400;
                        font-size: 42px;
                        color: #FFFFFF;
                        margin-right: 20px;
                    }

                    .testReport_pages12_content_left_li_right {
                        width: 540px;

                        .testReport_pages12_content_left_li_right_title {
                            font-family: Source Han Sans SC;
                            font-weight: 400;
                            font-size: 24px;
                            color: #5aaca9;
                        }

                        .testReport_pages12_content_left_li_right_text {
                            margin-top: 9px;
                            font-family: Source Han Sans SC;
                            font-weight: 400;
                            font-size: 20px;
                            color: #474F57;
                        }
                    }
                }

                .testReport_pages12_content_left_li:nth-last-of-type(1) {
                    margin-bottom: 0;
                }
            }

            .testReport_pages12_content_right {
                position: relative;
                width: 119px;
                margin-right: 20px;

                .testReport_pages12_content_right_img {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-112px);
                    height: 532px;
                    width: 282px;
                    right: 20px;
                }
            }
        }

    }
}
</style>
